<template>
  <div class="testimony">
    <img src="./images/wirecutter.svg" alt="">
    <blockquote class="text-center">
      <p class="quote">
        <slot>TunnelBear exceeded our expectations for trust and transparency, the most important factors to consider when you’re choosing the best VPN&nbsp;service.</slot>
      </p>
      <p>- Wirecutter (A New York Times Company)</p>
    </blockquote>
  </div>
</template>

<script>
export default {
  name: 'wirecutter',
  data () {
    return {}
  }
}
</script>

<style scoped lang="stylus" src="./wirecutter.styl" >

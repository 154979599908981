<template>
  <transition :name="isOverlay ? 'minimize': 'fade'">
    <div v-show="showModal" ref="modal" role="dialog" :aria-labelledby="labelID" class="view-wrap" data-cy="group--modal">
      <div @click="closeable && closeModal()" class="grey-bg"></div>
      <div :class="[size, {'overlay': isOverlay}]" class="modal-wrap">
        <div class="form-wrap">
          <button v-if="closeable" @click="closeModal()" class="plain close" aria-label="close"></button>
          <div class="form">
            <div class="form-content-holder">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    'closeable': Boolean,
    'size': String,
    'labelID': String,
    'isOverlay': {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: false,
      activeField: null
    }
  },
  created: function () {
    if (this.closeable) {
      window.addEventListener('keydown', this.escape)
    }
  },
  methods: {
    openModal: function () { // called by parent components using $refs
      this.showModal = true
      this.activeField = document.activeElement
      this.$store.dispatch('modalOpen', true)
      this.$nextTick(() => {
        const inputField = this.$refs.modal.querySelector('input')
        const button = this.$refs.modal.querySelectorAll('button')[1] // skip the close button
        if (inputField) {
          inputField.value = ''
          inputField.focus()
        } else if (button) {
          button.focus()
        }
      })
    },
    closeModal: function () {
      this.showModal = false
      this.$store.dispatch('modalOpen', false)
      if (this.isOverlay) this.$store.dispatch('showOverlay', false)
      setTimeout(() => {
        if (this.activeField) {
          this.activeField.focus()
          this.activeField = null
        }
      }, 100)
    },
    escape: function (e) {
      if (e.key === 'Escape') {
        this.closeModal()
      }
    }
  },
  destroyed: function () {
    window.removeEventListener('keydown', this.escape)
  }
}
</script>

<style scoped lang="stylus" src="./modal.styl" >

<template>
  <div class="counter" :class="{'counter-block container element element-medium': styleBlock}">
    <div class="timer" :class="{'row': styleBlock}">
      <div class="col" v-if="yearsToTarget">
        <p class="number"><span v-show="isSingleDigit(yearsToTarget)">0</span>{{ yearsToTarget }}</p>
        <p>year<span v-show="isPlural(yearsToTarget)">s</span></p>
      </div>
      <div class="col" v-if="daysToTarget">
        <p class="number"><span v-show="isSingleDigit(daysToTarget)">0</span>{{ daysToTarget}}</p>
        <p>day<span v-show="isPlural(daysToTarget)">s</span></p>
      </div>
      <div class="col">
        <p class="number"><span v-show="isSingleDigit(hoursToTarget)">0</span>{{ hoursToTarget}}</p>
        <p>hour<span v-show="isPlural(hoursToTarget)">s</span></p>
      </div>
      <div class="col">
        <p class="number"><span v-show="isSingleDigit(minsToTarget)">0</span>{{ minsToTarget}}</p>
        <p>min<span v-show="isPlural(minsToTarget)">s</span></p>
      </div>
      <div class="col">
        <p class="number"><span v-show="isSingleDigit(secsToTarget)">0</span>{{ secsToTarget}}</p>
        <p>secs</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'countdown-timer',
  props: {
    targetDate: {
      type: Date,
      required: true
    },
    styleBlock: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      secsToTarget: null,
      minsToTarget: null,
      hoursToTarget: null,
      daysToTarget: null,
      yearsToTarget: null,
      counter: undefined,
      msToTarget: null,
      countdownComplete: false
    }
  },
  mounted: function () {
    this.getDate()
  },
  methods: {
    isSingleDigit: function (interval) {
      return interval < 10
    },
    isPlural: function (interval) {
      return interval !== 1
    },
    getDate: function () {
      const currentDate = new Date()
      const targetDate = new Date(this.targetDate)
      const msToTarget = targetDate - currentDate

      const secInMs = 1000
      const minInMs = 60 * secInMs
      const hourInMs = 60 * minInMs
      const dayInMs = 24 * hourInMs
      const yearInMs = 365 * dayInMs

      const yearsToTarget = Math.floor(msToTarget / yearInMs)
      let secsRemaining = msToTarget % yearInMs
      const daysToTarget = Math.floor(secsRemaining / dayInMs)
      secsRemaining = secsRemaining % dayInMs
      const hoursToTarget = Math.floor(secsRemaining / hourInMs)
      secsRemaining = secsRemaining % hourInMs
      const minsToTarget = Math.floor(secsRemaining / minInMs)
      secsRemaining = secsRemaining % minInMs
      const secsToTarget = Math.floor(secsRemaining / secInMs)

      this.msToTarget = msToTarget
      this.yearsToTarget = yearsToTarget
      this.daysToTarget = daysToTarget
      this.hoursToTarget = hoursToTarget
      this.minsToTarget = minsToTarget
      this.secsToTarget = secsToTarget

      this.startCountdown()
    },
    startCountdown: function () {
      this.stopCountdown()
      this.counter = setInterval(() => {
        this.msToTarget -= 1000
        this.secsToTarget -= 1
        if (this.secsToTarget < 0) {
          this.secsToTarget = 59
          this.minsToTarget -= 1
        }
        if (this.minsToTarget < 0) {
          this.minsToTarget = 59
          this.hoursToTarget -= 1
        }
        if (this.hoursToTarget < 0) {
          this.hoursToTarget = 23
          this.daysToTarget -= 1
        }
        if (this.daysToTarget < 0) {
          this.daysToTarget = 364
          this.yearsToTarget -= 1
        }
        if (this.yearsToTarget && this.yearsToTarget < 0) {
          this.yearsToTarget = null
        }
      }, 1000)
    },
    stopCountdown: function () {
      if (this.counter) clearInterval(this.counter)
    }
  },
  watch: {
    msToTarget (value) {
      if (value <= 0) {
        this.secsToTarget = 0
        this.minsToTarget = 0
        this.hoursToTarget = 0
        this.daysToTarget = 0
        this.yearsToTarget = 0
        this.countdownComplete = true
        this.stopCountdown()
      }
    }
  },
  destroyed: function () {
    this.stopCountdown()
  }
}
</script>

<style scoped lang="stylus" src="./countdown-timer.styl" >

<template>
  <div class="nav-menu">
    <div class="nav-item">
      <a href="/team/account/overview">My&nbsp;Account</a>
    </div>
    <div class="nav-item">
      <a href="/team/account/team">Team</a>
    </div>
    <div class="nav-item visible-xs">
      <button class="plain" @click="$emit('logout')">Logout</button>
    </div>
    <div class="nav-item bottom">
      <a href="/team/account/invite" class="btn">Invite</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav-team-admin',
  data () {
    return {}
  }
}
</script>

<style scoped lang="stylus" src="./nav-team-admin.styl" >

<template>
  <div class="nav-menu">
    <div class="nav-items-wrap">
      <div class="nav-item" :class="{'current': current === 'pricing'}" @click="$emit('closeNav')">
        <router-link :to="{ name: 'pricing' }">Plans</router-link>
      </div>
      <nav-link-vpn
        :current="current === 'what-is-vpn'"
       @click="$emit('closeNav')"
      ></nav-link-vpn>
      <div class="nav-item" :class="{'current': current === 'download'}" @click="$emit('closeNav')">
        <router-link :to="{ name: 'download' }">Download</router-link>
      </div>
      <div class="nav-item">
        <router-link :to="{ name: 'help' }">Help</router-link>
      </div>
      <div class="nav-item" :class="{'current': current === 'overview'}" @click="$emit('closeNav')">
        <router-link :to="{ name: 'overview' }">My&nbsp;Account</router-link>
      </div>
      <div class="nav-item visible-xs">
        <button class="btn plain" @click="$emit('logout')">Logout</button>
      </div>
    </div>
    <div class="nav-item bottom" @click="$emit('closeNav')">
      <router-link :to="{ name: 'pricing' }" class="btn" :class="{'hollow': current === 'pricing'}">Upgrade</router-link>
    </div>
  </div>
</template>

<script>
import navLinkVpn from '../nav-link-vpn/nav-link-vpn'

export default {
  name: 'nav-free',
  props: ['current', 'appRoute'],
  components: {
    'nav-link-vpn': navLinkVpn
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="stylus" src="./nav-free.styl" >

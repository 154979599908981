<template>
  <div id="app" v_cloak v-cloak :class="[layout.bgColor ? 'bg-takeover ' + layout.bgColor : 'bg-dark', {'no-scroll': noScroll}]">
    <div class="app-content" @click.once="activeUser()">
      <nav-bar v-if="!layout.hideNav"
        :minimal="layout.minimalNav"
        :hideCtaButton="layout.hideCtaButton"
        :customCta="layout.customCta"
        @toggleScroll="noScroll = $event"></nav-bar>
      <router-view @modifyLayout="modifyLayout"></router-view>
      <site-footer :minimal="layout.minimalFooter" v-if="!layout.hideFooter"></site-footer>
    </div>
    <cookie-banner v-show="showCookieBanner"></cookie-banner>
  </div>
</template>

<script>
import nav from './components/nav/nav'
import footer from './components/footer/footer'
import cookieBanner from './components/cookie-banner/cookie-banner'
import metaContent from './data/meta.json'
import meta from './mixins/meta.js'
import services from './services/services.js'
import Cookies from 'js-cookie'
import utils from './utils/utils.js'

export default {
  name: 'app',
  mixins: [meta],
  data () {
    return {
      layout: {},
      metaContent,
      showCookieBanner: !Cookies.get('ac'),
      noScroll: false
    }
  },
  components: {
    'nav-bar': nav,
    'site-footer': footer,
    'cookie-banner': cookieBanner
  },
  created: function () {
    // meta content logic here
    this.$http.interceptors.response.use((response) => {
      if (response.headers && response.headers['tb-csrf-token']) {
        Cookies.set('XSRF-TOKEN', response.headers['tb-csrf-token'], { secure: true })
      }
      return response
    }, error => {
      return Promise.reject(error)
    })

    this.$http.interceptors.request.use((config) => {
      config.headers['TB-CSRF-Token'] = Cookies.get('XSRF-TOKEN')
      config.headers['tunnelbear-app-id'] = 'com.tunnelbear.web'
      config.headers['tunnelbear-app-version'] = '1.0.0'
      config.headers['tunnelbear-platform'] = utils.getBrowserType()
      config.headers['tunnelbear-platform-version'] = utils.getVersionString()
      if (config.url && !config.url.includes(this.ENV.apiBlogUrl) && config.url.includes('tunnelbear.com')) config.withCredentials = true

      if (!(config.data instanceof FormData) && config.headers['Content-Type'] !== 'application/json') {
        config.data = services.urlEncodeData(config.data)
      }

      if (process.env.NODE_ENV === 'development') {
        config.withCredentials = true
      }
      return config
    })
    this.$http.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    this.$store.$http = this.$http
    this.$store.ENV = this.ENV
    if (this.$store.getters.isLoggedIn) {
      this.$store.dispatch('getUserData')
    }
    this.$router.beforeEach((to, from, next) => {
      this.modifyLayout({})
      if (this.$store.getters.modalOpen) {
        this.$store.dispatch('modalOpen', false)
      }
      next()
    })
    this.setXSRF()
    this.meta()
    this.$router.afterEach((to, from) => {
      this.noScroll = false
      this.meta()
      this.activateOptimize()
    })
  },
  mounted: function () {
    this.activateOptimize()
  },
  methods: {
    // for google optimize
    activateOptimize: function () {
      const optimizeTargets = ['home']
      if (optimizeTargets.indexOf(this.$route.name) > -1 && this.ENV.name !== 'development') {
        this.$nextTick(() => {
          window.dataLayer.push({ 'event': 'optimize.activate' })
        })
      }
    },
    activeUser: function () {
      if (this.showCookieBanner) {
        Cookies.set('ac', true, {
          expires: 365,
          secure: true
        })
        this.$store.dispatch('activeUser')
      }
    },
    meta: function () {
      if (this.metaContent[this.$route.name]) {
        if (this.metaContent[this.$route.name].isCanonical) {
          this.applyMeta(this.metaContent[this.$route.name], this.$route.path)
        } else {
          this.applyMeta(this.metaContent[this.$route.name])
        }
      }
    },
    modifyLayout: function (modifier) {
      this.layout = {
        hideFooter: modifier.hideFooter || false,
        hideNav: modifier.hideNav || false,
        minimalFooter: modifier.minimalFooter || false,
        minimalNav: modifier.minimalNav || false,
        bgColor: modifier.bgColor || '',
        hideCtaButton: modifier.hideCtaButton || false,
        customCta: modifier.customCta || false
      }
    },
    setXSRF: function () {
      if (this.ENV.name !== 'development') {
        this.$http.get(this.ENV.coreBaseUrl + '/xzrf')
      }
    }
  }
}

</script>
<style src="./styles/main.styl" lang="stylus"></style>

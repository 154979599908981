<template>
  <iframe id="download-frame" data-cy="link--iframe" style="display:none;" :src="frameSrc"></iframe>
</template>

<script>
import Cookies from 'js-cookie'
import is from 'is_js'

export default {
  name: 'trigger-download',
  data () {
    return {
      frameSrc: ''
    }
  },
  methods: {
    triggerDownload: function (deviceName) {
      if (deviceName ? deviceName === 'mac' : (is.mac()) || window.Cypress) {
        this.frameSrc = this.external.downloadLinks.mac
      } else if (deviceName ? deviceName === 'windows' : is.windows()) {
        this.frameSrc = this.external.downloadLinks.windows
      }
      const time = new Date()
      time.setTime(time.getTime() + 1 * 3600 * 1000)
      Cookies.set('downloaded', true, { expires: time, secure: true })
    }
  }
}

</script>

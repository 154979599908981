// // The Vue build version to load with the `import` command
// // (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store.js'
import tunnels from './data/tunnels.js'
import services from './services/services.js'
import VueGtm from '@gtm-support/vue2-gtm'
import locationData from './data/location-data.json'
import redirects from './data/redirects.json'
import metaContent from './data/meta.json'
import axios from 'axios'
import announce from './components/announce/announce'
import imgSet from './components/img-set/img-set'
import externalLinks from './data/external-links.js'
import utils from './utils/utils.js'

utils.setGTMScript()

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.component('Announce', announce)
Vue.component('ImgSet', imgSet)

Vue.mixin({
  data () {
    return {
      ENV: process.env.CONFIG,
      locations: locationData,
      external: externalLinks,
      apps: {
        windows: { osSupport: '10 and later' },
        mac: { osSupport: '11.0 and later' },
        ios: { osSupport: '12.0 and later' },
        android: { osSupport: '8.1 and later' }
      },
      tunnels
    }
  },
  methods: {
    urlEncodeData: function (data) {
      let buffer = []
      for (const key in data) {
        if (!Object.prototype.hasOwnProperty.call(data, key)) continue
        const encodedKey = encodeURIComponent(key)
        const encodedValue = encodeURIComponent(data[key])
        buffer.push(encodedKey + '=' + encodedValue)
      }
      buffer = buffer.join('&')
      return buffer
    }
  }
})

/* eslint-disable no-constant-condition */
router.beforeEach((to, from, next) => {
  if (to.name === 'help') { // hack to show the backupcode alert on help page click
    store.dispatch('setBackupCodeAlert', true)
  }
  if (to.matched.some(record => record.meta.requiresAuth) && store.getters.tb4t) {
    const toSupportPath = to.path === '/support'
    if (!toSupportPath && (store.getters.tb4t === 'ADMIN' || store.getters.tb4t === 'MEMBER')) {
      window.location.href = '/team/account/overview'
      return
    }
  }
  if (metaContent[to.name]) {
    document.title = metaContent[to.name].title
  } else {
    document.title = metaContent.default.title
  }
  const urlParams = to.hash.split('?')
  const redirect = redirects[to.path + urlParams[0]]
  if (redirect) {
    const query = services.cleanQuery(urlParams[1])
    next({ path: redirect, query, meta: to.meta, params: to.params })
    return
  } else if (to.path.slice(-1) === '/' && to.path.length > 1) {
    const pathWithoutSlash = to.path.substring(0, to.path.length - 1)
    next({ path: pathWithoutSlash, query: to.query, hash: to.hash, meta: to.meta, params: to.params })
    return
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      store.commit('saveNextRoute', to)
      next({
        name: 'login'
      })
      return
    }
  }
  next()
})

Vue.use(VueGtm, {
  id: process.env.CONFIG.name === 'production' ? 'GTM-WHX2JQPN' : 'GTM-MSCM3SGQ',
  enabled: window.location.host.indexOf('tunnelbear.com') > -1, // disabled for prerender-spa-plugin
  vueRouter: router,
  defer: true,
  debug: false
})

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  router,
  store,
  components: {
    App
  },
  template: '<App/>'
})

if (window.Cypress) {
  window.app = app
  Vue.prototype.isCypress = true
}

<template>
  <div :class="addClass" aria-live="polite" aria-relevant="additions removals">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'announce',
  props: {
    addClass: String
  },
  data () {
    return {}
  }
}
</script>

<template>
  <div>
    <announce>
      <p v-if="submitError" class="alert alert-danger" data-cy="error--submit">{{submitError}}</p>
    </announce>
    <form name="loginForm" id="login-form" class="form-area auth" v-on:submit.prevent="submit" novalidate>
      <div class="form-row">
        <announce>
          <p class="field-error" :class="{ 'show-error' : errors.current === 'email' }" data-cy="error--email">Grr... That is not a valid email</p>
        </announce>
        <dynamic-input
          name="email"
          type="email"
          :placeholder="emailPlaceholder"
          autocomplete="email"
          @model="emailTyping"
          @focus="emailFocus"
          required>Email</dynamic-input>
      </div>
      <div class="form-row">
        <announce>
          <p class="field-error" :class="{ 'show-error' : errors.current === 'password' }" data-cy="error--password">Grr... Please enter a valid password</p>
        </announce>
        <dynamic-input
          name="password"
          type="password"
          @model="formData.password = $event"
          @blur="passwordBlur"
          @focus="passwordFocus"
          autocomplete="password"
          required>Password</dynamic-input>
      </div>
      <submit-button :finished="finished" :submitting="submitting" @submit="submit" data-cy="btn--login-existing">Log In</submit-button>
    </form>
  </div>
</template>

<script>
import dynamicInput from '../dynamic-input/dynamic-input'
import marketing from '../../services/marketing'
import submitButton from '@/components/submit-button/submit-button'
import is from 'is_js'
import validator from '../../mixins/validator.js'
import { eventBus } from '@/services/bus.js'

export default {
  name: 'login-form',
  props: {
    emailPlaceholder: String
  },
  components: {
    'dynamic-input': dynamicInput,
    'submit-button': submitButton
  },
  mixins: [validator],
  data () {
    return {
      is,
      formData: {
        email: this.emailPlaceholder || '',
        password: ''
      },
      submitted: false,
      submitting: false,
      finished: false,
      submitError: ''
    }
  },
  computed: {
    validations: function () {
      return {
        'email': [this.formData.email, this.is.email(this.formData.email)],
        'password': [this.formData.password]
      }
    }
  },
  methods: {
    emailFocus: function () {
      eventBus.$emit('emailInputFocus')
    },
    passwordFocus: function () {
      eventBus.$emit('passwordInputFocus')
    },
    passwordBlur: function () {
      eventBus.$emit('passwordInputBlur')
    },
    emailTyping: function (val) {
      this.formData.email = val
      eventBus.$emit('emailInputTyping', this.formData.email.length)
    },
    makeRequest: function () {
      if (this.submitting) return
      this.submitting = true
      this.submitError = ''
      const data = {
        username: this.formData.email,
        password: this.formData.password
      }
      this.$store.dispatch('login', data).then(() => {
        marketing.removeMarketingCookies()
        this.submitting = false
        this.finished = true
      }).catch((error) => {
        if ((error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)) {
          this.submitError = 'We can\'t seem to find that email and password combination, try another?'
        } else if (error.response && error.response.status === 429) {
          this.submitError = 'Hold your bears! You\'ve tried that too many times. Please try again in a few minutes.'
        } else {
          this.submitError = 'Grr, something went wrong when trying to log in. Try again or get in touch with support if you keep having issues.'
        }
        this.submitting = false
      })
    },
    submit: function () {
      this.submitted = true
      if (this.errors.all.length === 0) {
        this.makeRequest()
      }
    }
  }
}
</script>

<style scoped lang="stylus" src="./login-form.styl" >

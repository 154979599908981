<template>
  <div class="nav-menu">
    <div class="nav-item">
      <a href="/team/account/overview/member">My&nbsp;Account</a>
    </div>
    <div class="nav-item visible-xs">
      <button class="plain" @click="$emit('logout')">Logout</button>
    </div>
    <div class="nav-item bottom" @click="$emit('closeNav')">
      <router-link :to="{ name: 'download' }" class="btn" :class="{'hollow': current === 'download'}">Download</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav-team-member',
  props: ['current'],
  data () {
    return {}
  }
}
</script>

<style scoped lang="stylus" src="./nav-team-member.styl" >

import Cookies from 'js-cookie'

export default {
  paymentTracking: function (route) {
    const data = {}
    // users without a value get 'website'
    data.tbnp_mkt_email_id = Cookies.get('tb_ref') || 'website'
    Cookies.remove('tb_ref')
    data.tbaa_mkt_form_id = route.path
    return data
  },
  removeMarketingCookies: function () {
    Cookies.remove('tb_mkt')
  },
  removeAllCookies: function () {
    Cookies.remove('tb_ref')
    Cookies.remove('tb_mkt')
  },
  marketingParams: function () {
    const marketingTags = {}

    if (Cookies.getJSON('tb_mkt')) {
      Object.assign(marketingTags, Cookies.getJSON('tb_mkt'))
    } else {
      marketingTags.utm_source = 'website'
    }

    const prefixedTags = {}
    for (const unTypedKey in marketingTags) {
      if (Object.prototype.hasOwnProperty.call(marketingTags, unTypedKey)) {
        prefixedTags['tbaa_' + unTypedKey] = marketingTags[unTypedKey]
      }
    }

    return prefixedTags
  }
}

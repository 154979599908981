<template>
  <div class="icon-desc">
    <slot name="img"></slot>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'img-item',
  data () {
    return {}
  }
}
</script>

<style scoped lang="stylus" src="./img-item.styl">

<template>
  <ul class="countries clearfix">
    <li v-for="country in tunnelsAlphabetized" class="country" :class="rows" :key="country.spritePosition">
      <div class="flag" :style="`background-position : 0 ${(-15 * country.spritePosition)}px`"></div>
      <p>{{country.country}}</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'flags',
  mixins: [],
  data () {
    return {}
  },
  computed: {
    tunnelsAlphabetized: function () {
      const tunnelsArr = this.tunnels
      return tunnelsArr.sort((a, b) => {
        return (a.sortAs || a.country).localeCompare((b.sortAs || b.country), 'en')
      })
    }
  },
  props: {
    rows: {
      type: String
    }
  }
}
</script>

<style scoped lang="stylus" src="./flags.styl" >

<template>
  <button type="submit" @click.prevent="submit" class="btn btn-lg full-width submit-btn" :disabled="paySubmitting || submitting || finished || disabled">
    <transition name="fade">
      <div v-if="finished" key="finished" class="button-state checkmark"></div>
      <div v-else-if="paySubmitting || submitting" key="submitting" class="button-state spinner"></div>
    </transition>
    <span class="text" :class="{ 'hidden': paySubmitting || submitting || finished }">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'submit-button',
  props: ['finished', 'submitting', 'disabled'],
  data () {
    return {}
  },
  methods: {
    submit: function () {
      this.$emit('submit')
    }
  },
  computed: {
    paySubmitting: function () {
      return this.$store.getters.submitting
    }
  }
}
</script>

<style scoped lang="stylus" src="./submit-button.styl" >

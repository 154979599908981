const bearType = function (bear) {
  let type = 'free'
  if (bear.indexOf('Giant') > -1) {
    type = 'giant'
  } else if (bear.indexOf('Grizzly') > -1) {
    type = 'grizzly'
  }
  return type
}

const mobilePlan = function (bear) {
  let device = ''
  if (bear.indexOf('iOS') > -1) {
    device = 'ios'
  } else if (bear.indexOf('Android') > -1) {
    device = 'android'
  }
  return device
}

const expiryInfo = function (date, user) {
  const expireDate = new Date(date).getTime()
  const today = new Date().getTime()

  const aboutAYear = today + (1000 * 60 * 60 * 24 * 366)
  const withinAYear = (expireDate < aboutAYear)
  const aboutAMonth = today + (1000 * 60 * 60 * 24 * 30)
  const withinAMonth = (expireDate < aboutAMonth)

  return {
    expiringThisYear: (withinAYear && user.plan !== 'free' && !user.recurring && !user.mobileOnly),
    expiringSoon: (withinAMonth && user.plan !== 'free' && !user.recurring && !user.mobileOnly)
  }
}

const remainingData = function (data, cap, plan) {
  const number = Math.round(data / 1024 / 1024)
  let percent = 0

  if (plan !== 'free') {
    percent = 100
  } else if (cap) {
    percent = Math.round((data / cap) * 100)
  }

  return {
    number,
    percent,
    low: (percent < 20 && plan === 'free'),
    none: (number < 1 && plan === 'free')
  }
}

const checkSubscription = function (data, plan, mobileOnly) {
  const sub = {
    recurring: false,
    canStartSub: true
  }
  if (data.activeGOTB || data.isDunning ||
    (plan === 'grizzly' && data.yearlyPurchase === '0')) {
    sub.recurring = false
  } else {
    sub.recurring = data.paymentStatus && !(['FREE', 'PAID_YEARLY'].indexOf(data.paymentStatus) > -1)
  }
  // already a sub or two year plan or mobile plan
  if (sub.recurring || (data.yearlyPurchase === '0' && plan === 'grizzly' && !data.activeGOTB) || mobileOnly) {
    sub.canStartSub = false
  }

  return sub
}

export default {
  filterUserData (rawUserData) {
    const data = rawUserData

    if (rawUserData.bearType) {
      data.plan = bearType(rawUserData.bearType)
      if (mobilePlan(rawUserData.bearType)) {
        data.mobileOnly = mobilePlan(rawUserData.bearType)
      }
    }
    Object.assign(data, checkSubscription(rawUserData, data.plan, data.mobileOnly))
    if (rawUserData.fullVersionUntil) {
      Object.assign(data, expiryInfo(rawUserData.fullVersionUntil, data))
    }
    data.remainingData = remainingData(rawUserData.dataLeft, rawUserData.dataCap, data.plan)

    if (!!rawUserData.channel && rawUserData.channel === 'STRIPE') {
      data.cardOnFile = true
    }

    return data
  }
}

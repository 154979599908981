<template>
  <section id="network" :class="bg">
    <div class="section-inner">
      <div class="container">
        <div class="row vertical-center">
          <div class="world col-lg-5 hidden-xs">
            <img-set
              lazyload
              :imgSrc="require('@/components/country-access/images/earth-cutaway.svg')"
              :width="51" :height="80"/>
          </div>
          <div class="text col-xs-12 col-md-7">
            <slot name="copy">
              <h1 class="h3">Browse the internet from {{ tunnels.length }} countries</h1>
              <p>Discover something new, or tunnel back home to enjoy your favourite websites and apps while travelling or living abroad.</p>
            </slot>
            <div class="countries-container">
              <country-flags rows="rows"></country-flags>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import flags from '@/components/flags/flags'

export default {
  name: 'country-access',
  mixins: [],
  data () {
    return {}
  },
  props: {
    'bg': String
  },
  components: {
    'country-flags': flags
  }
}
</script>
<style scoped lang="stylus" src="./country-access.styl">

export default {
  methods: {
    applyMeta: function (metaTags, canonical) {
      if (!metaTags) { return }

      try {
        if (location.pathname.indexOf('/share') > -1) {
          document.head.querySelector('[property="og:url"]').content = process.env.CONFIG.baseURL + metaTags.image
          document.head.querySelector('[property="og:type"]').content = 'image/gif'
        } else {
          document.head.querySelector('[property="og:url"]').content = process.env.CONFIG.baseURL + location.pathname
          document.head.querySelector('[itemprop="description"]').content = metaTags.description
          document.head.querySelector('[property="og:type"]').content = 'article'
        }
        document.title = metaTags.title
        document.head.querySelector('[rel="canonical"]').setAttribute('href', process.env.CONFIG.baseURL + (canonical || location.pathname))
        document.head.querySelector('[name="description"]').content = metaTags.description
        document.head.querySelector('[itemprop="name"]').content = metaTags.title
        document.head.querySelector('[itemprop="image"]').content = process.env.CONFIG.baseURL + metaTags.image
      } catch (e) {
        // no tag found.
      }

      // Adding facebook and twitter meta tags
      Object.keys(metaTags).forEach(function (key) {
        let tag = metaTags[key]
        if (key === 'image') {
          tag = process.env.CONFIG.baseURL + tag
        }

        let twitterTag = ''
        if (key.indexOf('tw_') > -1 && tag) {
          twitterTag = document.head.querySelector('[name="twitter:' + key.substring(3) + '"]')
        } else {
          twitterTag = document.head.querySelector('[name="twitter:' + key + '"]')
        }
        if (twitterTag) {
          twitterTag.content = tag
        }

        const fbTag = document.head.querySelector('[property="og:' + key + '"]')
        if (fbTag) {
          fbTag.content = tag
        }
      })
    }
  }
}

export default {
  DELETE: `${process.env.CONFIG.coreBaseUrl}/delete`,
  GET_BILLING_DETAILS: `${process.env.CONFIG.coreBaseUrl}/api/getBillingDetails`,
  ACCOUNT: `${process.env.CONFIG.dashboardBaseUrl}/v2/account`,
  DEPRICATTED_ACCOUNT: `${process.env.CONFIG.coreBaseUrl}/api/getUserDetails`,
  TOKEN: `${process.env.CONFIG.dashboardBaseUrl}/v2/token`,
  MFA: `${process.env.CONFIG.dashboardBaseUrl}/v2/mfa`,
  MFA_RECOVERY: `${process.env.CONFIG.dashboardBaseUrl}/v2/mfa_recovery`,
  TOKEN_COOKIE: `${process.env.CONFIG.dashboardBaseUrl}/v2/tokenCookie`,
  CREATE_ACCOUNT: `${process.env.CONFIG.coreBaseUrl}/createAccount`,
  LOGOUT: `${process.env.CONFIG.coreBaseUrl}/api/logout`,
  REQUEST_RESET: `${process.env.CONFIG.coreBaseUrl}/requestReset`,
  SET_EMAIL_MFA: `${process.env.CONFIG.dashboardBaseUrl}/v2/set_email_mfa`,
  RESEND_EMFA: `${process.env.CONFIG.dashboardBaseUrl}/v2/resend_emfa`,
  RESEND_EMFA_LOGIN: `${process.env.CONFIG.dashboardBaseUrl}/v2/resend_emfa_login`,
  SET_TOTP_MFA: `${process.env.CONFIG.dashboardBaseUrl}/v2/set_totp_mfa`,
  GENERATE_NEW_RECOVERY_CODES: `${process.env.CONFIG.dashboardBaseUrl}/v2/generate_new_recovery_codes`,
  ENROLL_MFA: `${process.env.CONFIG.dashboardBaseUrl}/v2/enroll_mfa`,
  ENABLE_MFA: `${process.env.CONFIG.dashboardBaseUrl}/v2/enable_mfa`,
  DISABLE_MFA: `${process.env.CONFIG.dashboardBaseUrl}/v2/disable_mfa`,
  GET_MFA: `${process.env.CONFIG.dashboardBaseUrl}/v2/get_mfa`,
  GET_MFA_AUTH: `${process.env.CONFIG.dashboardBaseUrl}/v2/get_mfa_auth`,
  GET_RECOVERY_CODES_PDF: `${process.env.CONFIG.dashboardBaseUrl}/v2/get_recovery_codes_pdf`
}

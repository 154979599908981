<template>
  <div class="view-wrap">
    <div class="view-content">
      <section id="downloads">
        <div class="section-inner">
          <div class="container">
            <div class="section-inner-md text-center">
              <h1>Download TunnelBear</h1>
              <p>Select your platform below to get&nbsp;started.</p>
              <div class="download-row">
                <div class="download-item">
                  <h2>Desktop</h2>
                  <div class="btn-holder">
                    <button data-cy="btn--mac-download" id="mac-dl" class="btn btn-lg dl mac" @click="download('mac')"><div>Mac</div></button>
                    <p class="fine-print">64-bit macOS {{this.apps.mac.osSupport}}. <router-link :to="{name: 'whats-new-osx'}">What's new?</router-link></p>
                  </div>
                  <div class="btn-holder">
                    <button data-cy="btn--pc-download" id="pc-dl" class="btn btn-lg dl windows" @click="download('windows')"><div>Windows</div></button>
                    <p class="fine-print">Windows {{this.apps.windows.osSupport}}. <router-link :to="{name: 'whats-new-pc'}">What's New?</router-link></p>
                  </div>
                </div>
                <div class="download-item">
                  <h2>Mobile</h2>
                  <div data-cy="btn--ios-download" class="btn-holder">
                    <a :href="external.downloadLinks.ios" class="btn btn-lg dl ios"><div>iOS</div></a>
                    <p class="fine-print">iPads and iPhones with {{this.apps.ios.osSupport}}</p>
                  </div>
                  <div data-cy="btn--android-download" class="btn-holder">
                    <a :href="external.downloadLinks.android" class="btn btn-lg dl android"><div>Android</div></a>
                    <p class="fine-print">Android {{this.apps.android.osSupport}}</p>
                  </div>
                </div>
              </div>
              <div class="text-center copy">
                <h2>Browser Extensions</h2>
                <p>Lightweight extensions that only tunnel data inside your&nbsp;browser.</p>
              </div>
              <div class="download-row">
                <div data-cy="btn--chrome-extension" class="download-item btn-holder">
                  <a :href="external.downloadLinks.chrome" class="btn btn-lg grey dl chrome"><div>Chrome</div></a>
                  <p class="fine-print">Chrome 22+ <router-link :to="{name: 'chrome'}">Learn More</router-link></p>
                </div>
                <div data-cy="btn--firefox-extension" class="download-item btn-holder">
                  <a :href="external.downloadLinks.firefox" class="btn btn-lg grey dl firefox"><div>Firefox</div></a>
                  <p class="fine-print">Firefox 58+</p>
                </div>
                <div data-cy="btn--edge-extension" class="download-item btn-holder">
                  <a :href="external.downloadLinks.edge" class="btn btn-lg grey dl edge"><div>Edge</div></a>
                  <p class="fine-print">Edge 114+</p>
                </div>
              </div>
            </div>
            <div class="notices">
              <router-link :to="{ name: 'legal-notices'}" class="fine-print">Notice and Attribution</router-link>
              <a href="https://help.tunnelbear.com/hc/en-us/articles/360007120951" class="fine-print">Uninstall TunnelBear</a>
            </div>
             <p class="fine-print terms-policy-text">
                By downloading <strong>TunnelBear</strong>,
                you agree to the <router-link :to="{name: 'terms-of-service'}" target="_blank">Terms&nbsp;of&nbsp;Service</router-link>
                and <router-link :to="{name: 'privacy-policy'}" target="_blank">Privacy&nbsp;Policy</router-link>.</p>
          </div>
        </div>
      </section>
    </div>
    <trigger-download ref="download"></trigger-download>
  </div>
</template>

<script>
import triggerDownload from '@/components/trigger-download/trigger-download'

export default {
  name: 'download-devices',
  components: {
    'trigger-download': triggerDownload
  },
  data () {
    return {}
  },
  methods: {
    download: function (deviceName) {
      this.$refs.download.triggerDownload(deviceName)
    }
  }
}
</script>

<style scoped lang="stylus" src="./download-devices.styl" >

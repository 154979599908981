<template>
  <div class="box-wrap">
    <div class="box" :class="{ expanded: expanded }">
      <slot name="img"></slot>
      <slot name="title"></slot>
      <slot name="content"></slot>
    </div>
    <!-- mobile -->
    <div class="box mobile" :class="{ expanded: expanded }">
      <button @click="expanded = !expanded" class="btn faq-title plain dark" aria-label="learn more">
        <slot name="title"></slot>
        <img :class="{ 'expanded': expanded }" src="./images/plus.png" :alt="expanded ? 'minimize list item' : 'expand list item'">
      </button>
      <transition name="slide-fade">
        <div v-if="expanded" class="expand-content">
          <slot name="content"></slot>
          <slot name="img"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'list-box',
  props: ['initialExpand'],
  data () {
    return {
      expanded: this.initialExpand
    }
  }
}
</script>

<style scoped lang="stylus" src="./list-box.styl">

<template>
  <div>
    <iframe
      id="captcha-form"
      :src="iframeURL"
      title="Prove you're a human (sadly necessary 😢)"
    ></iframe>
    <p v-if="captchaError !== null || showTimeoutMessage">
      <span v-if="captchaError === 'bad-message'">
        Oh no, looks like couldn't understand a system message!
      </span>
      <span v-else-if="captchaError === 'missing-token'">
        Uh oh, looks like we lost a system message!
      </span>
      <span v-else-if="captchaError !== null">
        Sorry, looks like something unexpected went wrong!
      </span>
      <span v-else-if="showTimeoutMessage">
        Having trouble?
      </span>
      Our friendly support bears are always available through the <router-link :to="{name: 'support'}">support form</router-link>
    </p>
  </div>
</template>

<script>
import services from '../../services/services.js'
import validator from '../../mixins/validator.js'

export default {
  name: 'captcha-form',
  data () {
    return {
      targetOrigin: process.env.CONFIG.apiBaseUrl,
      timeoutMessageId: null,
      showTimeoutMessage: false,
      captchaError: null
    }
  },
  computed: {
    iframeURL () {
      return `${this.targetOrigin}/dashboard/web/v2/captchaChallengeToken`
    }
  },
  methods: {
    handleMessage (event) {
      if (event.origin !== this.targetOrigin) {
        return
      }
      let token
      window.clearTimeout(this.timeoutMessageId)
      try {
        token = JSON.parse(event.data).token
      } catch (e) {
        this.captchaError = 'bad-message'
        return
      }
      if (!token) {
        this.captchaError = 'missing-token'
        return
      }
      this.$emit('getCaptchaTokenFromCaptchaForm', token)
    },
    captchaTimedOut () {
      this.showTimeoutMessage = true
    }
  },
  mounted () {
    window.addEventListener('message', this.handleMessage, false)
    if (process.IS_TEST) {
      this.$emit('getCaptchaTokenFromCaptchaForm', 'captchaTokenShouldAppearInTestOnly')
    }
    // if we haven't received a message within 1 minute of starting the captcha, maybe something went wrong: suggest reaching out to support if they're having issues.
    this.timeoutMessageId = window.setTimeout(this.captchaTimedOut, 60 * 1000)
  },
  beforeDestroy () {
    window.removeEventListener('message', this.handleMessage, false)
    window.clearTimeout(this.timeoutMessageId)
  }
}
</script>

<style scoped lang="stylus" src="./captcha-form.styl" >

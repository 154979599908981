export default {
  computed: {
    errors: function () {
      let currentError = ''
      const errors = Object.keys(this.validations).map(name => {
        for (const rule of this.validations[name]) {
          if (!rule) return name
        }
        return false
      }).filter((error) => !!error)
      if (errors.length > 0 && this.submitted) {
        currentError = errors[0]
      }
      return {
        all: errors,
        current: currentError
      }
    }
  }
}

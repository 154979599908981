<template>
  <div>
    <div v-if="portalType === 'login'">
      <div class="copy">
        <slot name="login-copy">
          <h2>Log In</h2>
          <p v-if="reset" class="alert alert-success">Your password reset email has&nbsp;been&nbsp;sent!</p>
          <p v-else :id="labelID">Sign in to your account</p>
        </slot>
      </div>
      <div class="form-wrap" :class="size">
        <login-form :emailPlaceholder="emailPlaceholder"></login-form>
      </div>
      <p v-if="forgotLink" class="fine-print-lg">I forgot <router-link :to="{name: 'forgot'}"> my password</router-link>.</p>
      <p v-else class="fine-print-lg">I forgot <button @click="currentFace = 'forgot'" class="btn plain link"> my password</button>.</p>
      <div v-show="!hideSignup">
        <p v-if="portalType === 'login'" class="mtop-20">Don't have a TunnelBear account? <button class="plain link" @click="currentFace = 'signup'">Sign&nbsp;up</button></p>
        <p v-else class="mtop-20">Don't have a TunnelBear account? <router-link :to="{ name: 'signup', query: $route.query}" class="plain link">Sign&nbsp;up</router-link></p>
      </div>
    </div>
    <div v-else-if="portalType === 'forgot'">
      <div class="copy">
        <slot name="forgot-copy">
          <h2>Password reset</h2>
          <p>Enter your email address below and we'll send you a link to reset your password.</p>
        </slot>
      </div>
      <div class="form-wrap" :class="size">
        <forgot-form @success="successReset"></forgot-form>
        <p class="mtop-20">Take me <button @click="currentFace = 'login'" class="btn plain link">back to Login</button>.</p>
      </div>
    </div>
    <div v-else-if="portalType === 'captcha'">
      <div class="copy">
        <slot name="captcha-copy">
          <h2>Almost done&hellip;</h2>
          <p :id="labelID">Bear with us!</p>
        </slot>
      </div>
      <div class="form-wrap" :class="size">
        <captcha-form ref="captchaForm" @success="successReset" @getCaptchaTokenFromCaptchaForm="emitCaptchaTokenToPaymentCharge"></captcha-form>
      </div>
    </div>
    <div v-else>
      <div class="copy">
        <slot name="signup-copy">
          <h2>You're almost there</h2>
          <p :id="labelID">Sign up to get TunnelBear</p>
        </slot>
      </div>
      <div class="form-wrap" :class="size">
        <signup-form></signup-form>
      </div>
      <p v-if="portalType" class="mtop-10">Or <button class="plain link" @click="currentFace = 'login'" data-cy="btn--login-existing">use an existing account</button></p>
      <p v-else class="mtop-20">Or <router-link class="plain link" :to="{ name: 'login', query: $route.query}">use an existing account</router-link></p>
    </div>
  </div>
</template>

<script>
import captchaForm from '@/components/captcha-form/captcha-form'
import forgotForm from '@/components/forgot-form/forgot-form'
import loginForm from '@/components/login-form/login-form'
import signupForm from '@/components/signup-form/signup-form'

export default {
  name: 'portal',
  props: {
    'forgotLink': Boolean,
    'type': String,
    'size': String,
    'labelID': String,
    'hideSignup': Boolean
  },
  components: {
    'captcha-form': captchaForm,
    'forgot-form': forgotForm,
    'login-form': loginForm,
    'signup-form': signupForm
  },
  data () {
    return {
      currentFace: this.type,
      emailPlaceholder: '',
      reset: false
    }
  },
  computed: {
    portalType: function () {
      return this.currentFace || this.$route.name
    }
  },
  methods: {
    successReset: function (email) {
      setTimeout(() => {
        this.currentFace = 'login'
      }, 1500)
      this.reset = true
      this.emailPlaceholder = email
    },
    emitCaptchaTokenToPaymentCharge: function (captchaToken) {
      this.$emit('getCaptchaTokenFromPortal', captchaToken)
    }
  }
}
</script>

<style scoped lang="stylus" src="./portal.styl" >

export default {
  twitterUrl: 'https://www.twitter.com/theTunnelBear',
  facebookUrl: 'https://www.facebook.com/TunnelBear',
  instagramUrl: 'https://www.instagram.com/TunnelBear',
  telegramUrl: 'https://t.me/tunnelbearofficial',
  remembear: 'https://www.remembear.com',
  help: 'https://help.tunnelbear.com',
  audit: 'https://www.tunnelbear.com/blog/tunnelbear-completes-5th-annual-independant/',
  affiliate: 'https://signup.cj.com/member/signup/publisher/?cid=5172184',
  downloadLinks: {
    windows: 'https://tunnelbear.s3.amazonaws.com/downloads/pc/TunnelBear-Installer.exe',
    mac: 'https://s3.amazonaws.com/tunnelbear/downloads/mac/TunnelBear.zip',
    ios: 'https://geo.itunes.apple.com/app/tunnelbear-vpn-unblock-websites/id564842283?mt=8&at=1010l9nk',
    android: 'https://play.google.com/store/apps/details?id=com.tunnelbear.android',
    chrome: 'https://chrome.google.com/webstore/detail/tunnelbear-vpn/omdakjcmkglenbhjadbccaookpfjihpa',
    opera: 'https://chrome.google.com/webstore/detail/tunnelbear-vpn/omdakjcmkglenbhjadbccaookpfjihpa',
    edge: 'https://microsoftedge.microsoft.com/addons/detail/tunnelbear-vpn/ogemdakneofkpppkcfkgmbiopdpioipj',
    firefox: 'https://addons.mozilla.org/en-US/firefox/addon/tunnelbear-vpn-firefox/',
    blocker: 'https://chrome.google.com/webstore/detail/tunnelbear-blocker/bebdhgdigjiiamnkcenegafmfjoghafk'
  }
}

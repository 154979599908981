export default {
  getBrowserType () {
    const userAgent = navigator.userAgent
    let browserName
    if (userAgent.indexOf('OPR') >= 0 || userAgent.indexOf('Opera') >= 0) {
      browserName = 'Opera'
    } else if (userAgent.indexOf('Firefox') >= 0) {
      browserName = 'Firefox'
    } else if (userAgent.indexOf('Edg') >= 0) {
      browserName = 'Edge'
    } else if (userAgent.indexOf('Chrome') >= 0) {
      browserName = 'Chrome'
    } else if (userAgent.indexOf('Safari') >= 0) {
      browserName = 'Safari'
    } else {
      browserName = 'unknown'
    }
    return browserName
  },
  getVersionString () {
    const userAgent = navigator.userAgent
    let version
    if (userAgent.indexOf('OPR/') >= 0 || userAgent.indexOf('Opera/') >= 0) {
      version = userAgent.split('OPR/')[1]
    } else if (userAgent.indexOf('Firefox/') >= 0) {
      version = userAgent.split('Firefox/')[1]
    } else if (userAgent.indexOf('Edg/') >= 0) {
      version = userAgent.split('Edg/')[1]
    } else if (userAgent.indexOf('Chrome/') >= 0) {
      version = userAgent.split('Chrome/')[1]
    } else if (userAgent.indexOf('Safari/') >= 0) {
      version = userAgent.split('Safari/')[1]
    }
    return version ? version.split('.')[0] : 'unknown' // default version in case of weird user agent
  },
  setGTMScript () {
    const scriptTag = document.createElement('script')
    scriptTag.async = true
    let gTagsrc = ''
    if (process.env.CONFIG.name === 'production') {
      gTagsrc = 'https://www.googletagmanager.com/gtag/js?id=G-B7YKGDK379'
    } else {
      gTagsrc = 'https://www.googletagmanager.com/gtag/js?id=G-JYPRYS1EGD'
    }
    scriptTag.setAttribute('src', gTagsrc)
    document.getElementsByTagName('head')[0].appendChild(scriptTag)

    window.dataLayer = window.dataLayer || []
    function gtag () { window.dataLayer.push(arguments) }
    gtag('js', new Date())
    const gtmID = process.env.CONFIG.name === 'production' ? 'G-B7YKGDK379' : 'G-JYPRYS1EGD'
    gtag('config', gtmID)
  }
}

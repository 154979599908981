import Vue from 'vue'
import Router from 'vue-router'
import is from 'is_js'
import jobOpenings from '@/data/job-openings.json'
import store from '@/store.js'

import home from '@/views/home/home'
import pricing from '@/views/pricing/pricing'
import oldPricing from '@/views/pricing-old/pricing-old'
import apps from '@/views/apps/apps'
import downloadDevices from '@/views/download-devices/download-devices'
import download from '@/views/download/download'
import whatIsVpn from '@/views/what-is-vpn/what-is-vpn'

// lazy load components rather than loading them all at once
const mac = () => import('@/views/app-mac/app-mac')
const android = () => import('@/views/app-android/app-android')
const blocker = () => import('@/views/app-blocker/app-blocker')
const browser = () => import('@/views/app-browser/app-browser')
const ios = () => import('@/views/app-ios/app-ios')
const windows = () => import('@/views/app-windows/app-windows')

const about = () => import('@/views/about/about')
const affiliate = () => import('@/views/affiliate/affiliate')
const internetFreedomHub = () => import('@/views/internet-freedom-hub/internet-freedom-hub')
const ngoSupportNetwork = () => import('@/views/ngo-support-network/ngo-support-network')
const teams = () => import('@/views/teams/teams')

const whatsNew = () => import('@/views/whats-new/whats-new')
const chrome = () => import('@/views/chrome/chrome')
const coloringBook = () => import('@/views/coloring-book/coloring-book')

const bandwidthSupport = () => import('@/views/bandwidth-support/bandwidth-support')

const blockerApp = () => import('@/views/blocker/blocker')
const blockerAcknowledgement = () => import('@/views/blocker-acknowledgement/blocker-acknowledgement')
const blockerInfo = () => import('@/views/blocker-info/blocker-info')
const blockerInstalled = () => import('@/views/blocker-installed/blocker-installed')
const blockerPrivacyPolicy = () => import('@/views/blocker-privacy-policy/blocker-privacy-policy')
const blockerReports = () => import('@/views/blocker-reports/blocker-reports')
const buy = () => import('@/views/buy/buy')

const features = () => import('@/views/features/features')
const legalNotices = () => import('@/views/legal-notices/legal-notices')

const account = () => import('@/views/account/account')
const subscription = () => import('@/views/subscription/subscription')
const overview = () => import('@/views/overview/overview')
const privacy = () => import('@/views/privacy/privacy')
const billing = () => import('@/views/billing/billing')
const downgrade = () => import('@/views/downgrade/downgrade')
const renew = () => import('@/views/renew/renew')
const authentication = () => import('@/views/authentication/authentication')
const forgot = () => import('@/views/forgot/forgot')
const remembearIncluded = () => import('@/views/remembear-included/remembear-included')
const remembearThanks = () => import('@/views/remembear-thanks/remembear-thanks')
const remove = () => import('@/views/remove/remove')
const moreData = () => import('@/views/more-data/more-data')
const freeData = () => import('@/views/free-data/free-data')
const twitter = () => import('@/views/twitter/twitter')
const feedback = () => import('@/views/feedback/feedback')
const gift = () => import('@/views/gift/gift')
const giftThanks = () => import('@/views/gift-thanks/gift-thanks')
const giftAccept = () => import('@/views/gift-accept/gift-accept')
const giftSuccess = () => import('@/views/gift-success/gift-success')
const extensionLanding = () => import('@/views/extension-landing/extension-landing')
const upgraded = () => import('@/views/upgraded/upgraded')
const trial = () => import('@/views/trial/trial')
const pay = () => import('@/views/pay/pay')
const order = () => import('@/views/order/order')
const updatePayment = () => import('@/views/update-payment/update-payment')
const redeem = () => import('@/views/redeem/redeem')
const redeemPortal = () => import('@/views/redeem/components/redeem-portal/redeem-portal')
const redeemCoupon = () => import('@/views/redeem/components/redeem-coupon/redeem-coupon')
const redeemDownload = () => import('@/views/redeem/components/redeem-download/redeem-download')
const reset = () => import('@/views/reset/reset')
const emailPreferences = () => import('@/views/email-preferences/email-preferences')

const termsOfService = () => import('@/views/terms-of-service/terms-of-service')
const privacyPolicy = () => import('@/views/privacy-policy/privacy-policy')
const autorenewPolicy = () => import('@/views/autorenew-policy/autorenew-policy')
const reviews = () => import('@/views/reviews/reviews')

const jobsIndex = () => import('@/views/jobs-index/jobs-index')
const jobs = () => import('@/views/jobs/jobs')

const support = () => import('@/views/support/support')

const accountConfirmed = () => import('@/views/account-confirmed/account-confirmed')
const accountUpdated = () => import('@/views/account-updated/account-updated')
const unsubscribed = () => import('@/views/unsubscribed/unsubscribed')
const security = () => import('@/views/security/security')
const mfaAuth = () => import('@/views/mfa-auth/mfa-auth')
const mfaEnableDisable = () => import('@/views/mfa-setup/mfa-enable-disable/mfa-enable-disable')
const mfaSelectTypes = () => import('@/views/mfa-setup/mfa-select-types/mfa-select-types')
const mfaTOTPSetup = () => import('@/views/mfa-setup/mfa-totp-setup/mfa-totp-setup')
const mfaEmailSetup = () => import('@/views/mfa-setup/mfa-email-setup/mfa-email-setup')
const generateBackupCode = () => import('@/views/mfa-setup/generate-backup-code/generate-backup-code')
const tbByMcafee = () => import('@/views/tb-by-mcafee/tb-by-mcafee')
const tbByMcafeeCheckout = () => import('@/views/tb-by-mcafee-checkout/tb-by-mcafee-checkout')

Vue.use(Router)
export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/help',
      name: 'help',
      beforeEnter: (to, from, next) => {
        location.href = 'https://help.tunnelbear.com/hc/en-us'
      }
    },
    {
      path: '/tunnelbear-by-mcafee',
      name: 'tb-by-mcafee',
      component: tbByMcafee
    },
    {
      path: '/tunnelbear-by-mcafee/checkout',
      name: 'tb-by-mcafee-checkout',
      component: tbByMcafeeCheckout
    },
    {
      path: '/',
      name: 'home',
      alias: '/twitter',
      component: home
    },
    {
      path: '/download',
      alias: '/account/download',
      name: 'download',
      component: download,
      beforeEnter: (to, from, next) => {
        const unsupported = is.desktop() && is.not.mac() && is.not.windows()
        if (window.location.host === 'www.tunnelbear.com' && unsupported) {
          next({ replace: true, name: 'download-devices' })
        } else {
          next()
        }
      }
    },
    {
      path: '/download-devices',
      name: 'download-devices',
      component: downloadDevices
    },
    {
      path: '/what-is-vpn',
      name: 'what-is-vpn',
      component: whatIsVpn
    },
    {
      path: '/email-preferences',
      name: 'email-preferences',
      component: emailPreferences
    },
    {
      path: '/features',
      name: 'features',
      component: features
    },
    {
      path: '/about',
      name: 'about',
      component: about
    },
    {
      path: '/affiliate',
      alias: ['/partners',
        '/partner'],
      name: 'affiliate',
      component: affiliate
    },
    {
      path: '/whats-new-osx',
      name: 'whats-new-osx',
      component: whatsNew
    },
    {
      path: '/whats-new-pc',
      name: 'whats-new-pc',
      component: whatsNew
    },
    {
      path: '/apps',
      name: 'apps',
      component: apps,
      children: [
        {
          path: 'ios',
          name: 'ios',
          component: ios
        },
        {
          path: 'android',
          name: 'android',
          component: android
        },
        {
          path: 'mac',
          name: 'mac',
          component: mac
        },
        {
          path: 'windows',
          name: 'windows',
          component: windows
        },
        {
          path: 'browser',
          name: 'browser',
          component: browser
        },
        {
          path: 'blocker',
          name: 'blocker',
          component: blocker
        }
      ],
      beforeEnter: (to, from, next) => {
        if (to.path === '/apps') {
          let app
          if (is.mobile() || is.tablet()) {
            app = is.android() ? 'android' : 'ios'
          } else {
            app = is.windows() ? 'windows' : 'mac'
          }
          next({ replace: true, name: app })
        } else {
          next()
        }
      }
    },
    {
      path: '/tunnelbear-for-chrome',
      name: 'chrome',
      component: chrome
    },
    {
      path: '/blocker/',
      name: 'blocker-app',
      component: blockerApp,
      children: [
        {
          path: 'acknowledgement',
          name: 'blocker-acknowledgement',
          component: blockerAcknowledgement
        },
        {
          path: 'info',
          name: 'blocker-info',
          component: blockerInfo
        },
        {
          path: 'reports',
          name: 'blocker-reports',
          component: blockerReports
        },
        {
          path: 'installed',
          name: 'blocker-installed',
          component: blockerInstalled
        },
        {
          path: 'privacy-policy',
          name: 'blocker-privacy-policy',
          component: blockerPrivacyPolicy
        },
        {
          path: '*', redirect: '../apps/blocker'
        }
      ],
      beforeEnter: (to, from, next) => {
        if (to.path === '/blocker') {
          next({ replace: true, name: 'blocker' })
        } else {
          next()
        }
      }
    },
    {
      path: '/coloring-book',
      alias: '/colouring-book',
      name: 'coloring-book',
      component: coloringBook
    },
    {
      path: '/account',
      name: 'account',
      component: account,
      meta: { requiresAuth: true, noScroll: true },
      children: [
        {
          path: 'overview',
          name: 'overview',
          alias: 'subscription',
          component: overview
        },
        {
          path: 'privacy',
          name: 'privacy',
          component: privacy
        },
        {
          path: 'billing',
          name: 'billing',
          component: billing
        },
        {
          path: 'security',
          name: 'security',
          component: security,
          redirect: { name: 'mfa-enable-disable' },
          children: [
            {
              path: '',
              name: 'mfa-enable-disable',
              component: mfaEnableDisable
            },
            {
              path: 'generate-backup-code',
              name: 'generate-backup-code',
              component: generateBackupCode
            },
            {
              path: 'mfa-select-types',
              name: 'mfa-select-types',
              component: mfaSelectTypes
            },
            {
              path: 'mfa-email-setup',
              name: 'mfa-email-setup',
              component: mfaEmailSetup
            },
            {
              path: 'mfa-totp-setup',
              name: 'mfa-totp-setup',
              component: mfaTOTPSetup
            }
          ],
          beforeEnter: (to, from, next) => {
            const isToRoute = to.name === 'mfa-enable-disable'
            if (store.getters.isMFAAuthTokenExpired && !isToRoute) {
              next({ replace: true, name: 'mfa-enable-disable' })
            } else {
              next()
            }
          }
        },
        {
          path: 'more-data',
          name: 'more-data',
          component: moreData,
          meta: {
            requiresAuth: true,
            noScroll: true
          }
        }
      ],
      beforeEnter: (to, from, next) => {
        if (to.path === '/account') {
          next({ replace: true, name: 'overview' })
        } else {
          next()
        }
      }
    },
    {
      path: '/account/gift',
      name: 'gift',
      component: gift
    },
    {
      path: '/account/gift/thanks',
      name: 'gift-thanks',
      component: giftThanks
    },
    {
      path: '/account/gift/success',
      name: 'gift-success',
      component: giftSuccess
    },
    {
      path: '/account/gift/accept',
      name: 'gift-accept',
      component: giftAccept
    },
    {
      path: '/account/twitter',
      name: 'twitter',
      component: twitter,
      meta: {
        requiresAuth: true,
        noScroll: true
      }
    },
    {
      path: '/account/confirmed',
      alias: ['/account-confirmed',
        '/verify-email-landing-page'],
      name: 'account-confirmed',
      component: accountConfirmed
    },
    {
      path: '/account/updated',
      alias: '/account-updated',
      name: 'account-updated',
      component: accountUpdated
    },
    {
      path: '/account/update-payment',
      name: 'account-update',
      component: updatePayment
    },
    {
      path: '/account/free-data',
      name: 'free-data',
      component: freeData
    },
    {
      path: '/account/renew',
      alias: '/account/switch',
      name: 'renew',
      component: renew,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/upgraded',
      name: 'account-upgraded',
      component: upgraded
    },
    {
      path: '/account/downgrade',
      name: 'downgrade',
      component: downgrade,
      meta: { requiresAuth: true }
    },
    {
      path: '/redeem',
      name: 'redeem',
      component: redeem,
      children: [
        {
          path: 'welcome',
          name: 'redeem-portal',
          component: redeemPortal
        },
        {
          path: 'coupon',
          name: 'redeem-coupon',
          component: redeemCoupon
        },
        {
          path: 'download',
          name: 'redeem-download',
          component: redeemDownload
        }
      ],
      beforeEnter: (to, from, next) => {
        if (to.path === '/redeem') {
          next({ replace: true, name: 'redeem-portal', query: to.query, hash: to.hash })
        } else {
          next()
        }
      }
    },
    {
      path: '/account/reset',
      name: 'reset',
      component: reset
    },
    {
      path: '/account/login',
      name: 'login',
      alias: ['/login', '/log-in'],
      component: authentication
    },
    {
      path: '/account/signup',
      name: 'signup',
      alias: ['/signup', '/sign-up'],
      component: authentication
    },
    {
      path: '/remembear/included',
      name: 'remembear-included',
      component: remembearIncluded
    },
    {
      path: '/remembear/thanks',
      name: 'remembear-thanks',
      component: remembearThanks
    },
    {
      path: '/account/forgot',
      name: 'forgot',
      component: forgot
    },
    {
      path: '/account/feedback',
      name: 'feedback',
      component: feedback
    },
    {
      path: '/account/feedback/blocker',
      name: 'feedback-blocker',
      component: feedback
    },
    {
      path: '/account/unsubscribed',
      alias: '/monthly-reset-unsubscribed',
      name: 'unsubscribed',
      component: unsubscribed
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: privacyPolicy
    },
    {
      path: '/autorenew-policy',
      name: 'autorenew-policy',
      component: autorenewPolicy
    },
    {
      path: '/account/remove',
      name: 'remove',
      component: remove,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/logged-in',
      name: 'logged-in',
      component: extensionLanding,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/signed-up',
      name: 'signed-up',
      component: extensionLanding,
      meta: { requiresAuth: true }
    },
    {
      path: '/account/start',
      name: 'month-trial',
      component: trial,
      meta: { isPaymentForm: true }
    },
    {
      path: '/account/buy',
      name: 'buy',
      component: buy
    },
    {
      path: '/account/checkout',
      alias: ['/account/purchase', '/account/upgrade', '/account/checkout-a'],
      name: 'checkout',
      component: pay,
      meta: { isPaymentForm: true }
    },
    {
      path: '/sale',
      redirect: '/account/checkout'
    },
    {
      path: '/account/order',
      name: 'order',
      component: order,
      meta: { isPaymentForm: true }
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: pricing
    },
    {
      path: '/terms-of-service',
      alias: '/tos',
      name: 'terms-of-service',
      component: termsOfService
    },
    {
      path: '/reviews',
      name: 'reviews',
      component: reviews
    },
    {
      path: '/jobs',
      alias: '/careers',
      name: 'jobs-index',
      component: jobsIndex
    },
    {
      path: '/notices',
      name: 'legal-notices',
      component: legalNotices
    },
    {
      path: '/support',
      name: 'support',
      component: support // requiresAuth/login overlay is implemented in the support component directly. With a login bypass ability for auth-less support requests
    },
    {
      path: '/jobs/:job',
      name: 'jobs',
      component: jobs,
      beforeEnter: (to, from, next) => {
        if (jobOpenings[to.params.job]) {
          next()
        } else {
          next({ replace: true, name: 'jobs-index' })
        }
      }
    },
    {
      path: '/teams',
      alias: '/business',
      name: 'teams',
      component: teams
    },
    {
      path: '/ngo-support-network',
      name: 'ngo-support-network',
      component: ngoSupportNetwork
    },
    {
      path: '/internet-freedom-hub',
      alias: '/anticensorship-hub',
      name: 'internet-freedom-hub',
      component: internetFreedomHub
    },
    {
      path: '/bandwidth-support',
      name: 'bandwidth-support',
      component: bandwidthSupport
    },
    {
      path: '/account/*',
      redirect: { name: 'overview' }
    },
    {
      path: '/mfa-auth',
      name: 'mfa-auth',
      component: mfaAuth
    },
    {
      path: '*',
      redirect: { name: 'home' }
    }
  ],
  scrollBehavior: function (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

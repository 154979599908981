<template>
  <img v-if="isSvg"
    :data-cy="hasDimensions ? 'image--svg-lazyload' : undefined"
    :src="source"
    :alt="alt"
    :class="[classes, lazyloaded ? 'lazyloaded' : undefined]">
  <picture v-else>
    <source v-if="webpSourceSet"
      :srcset="webpSourceSet"
      type="image/webp">
    <source v-if="sourceSet && !hasDimensions"
      :srcset="sourceSet">
    <img
      ref="image"
      :data-cy="hasDimensions ? 'image--lazyload' : undefined"
      :class="[classes, lazyloaded ? 'lazyloaded' : undefined]"
      :src="source"
      :alt="alt"
      :sizes="hasDimensions ? imgWidth : undefined"
      :srcset="hasDimensions ? sourceSet : undefined">
  </picture>
</template>

<script>

import is from 'is_js'

export default {
  name: 'img-set',
  data () {
    return {
      observer: undefined,
      sourceSet: '',
      source: '',
      webpSourceSet: '',
      isSvg: this.imgSrc.indexOf('.svg') > -1 || this.imgSrc.indexOf('/svg') > -1,
      hasDimensions: !!(this.width) && !!(this.height) && !!(this.lazyload),
      imgWidth: '',
      lazyloaded: false,
      is
    }
  },
  props: {
    imgSrc: {
      type: String,
      required: true
    },
    imgSrc2x: {
      type: String,
      required: false,
      default: ''
    },
    webpSrc: {
      type: String,
      required: false,
      default: ''
    },
    webpSrc2x: {
      type: String,
      required: false,
      default: ''
    },
    lazyload: {
      // images immediately visible on page load should NOT be lazyloaded
      type: Boolean,
      required: false,
      default: false
    },
    alt: {
      type: String,
      required: false,
      default: ''
    },
    classes: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: Number,
      required: false,
      default: undefined
    },
    height: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  mounted: function () {
    if (!this.lazyload || this.is.ie()) {
      this.mountImages()
      this.warnMissingImages()
      return
    }

    if (this.hasDimensions) {
      this.createCanvas(this.width, this.height)
      setTimeout(() => {
        this.getSizes()
      }, 100)
    }

    const options = {
      threshold: 0,
      rootMargin: '400px 0px'
    }
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0] && entries[0].isIntersecting) {
        this.mountImages(this.width)
        this.warnMissingImages()
        this.lazyloaded = true
      }
    }, options)
    this.observer.observe(this.$el)
  },
  methods: {
    mountImages: function (width) {
      if (this.imgSrc2x) {
        this.sourceSet = `${this.imgSrc + ' ' + (this.hasDimensions ? width + 'w' : '1x')},
        ${this.imgSrc2x + ' ' + (this.hasDimensions ? (width * 2) + 'w' : '2x')}`
      }
      if (this.webpSrc && this.webpSrc2x) {
        this.webpSourceSet = `${this.webpSrc} 1x, ${this.webpSrc2x} 2x`
      }
      this.source = this.imgSrc
    },
    createCanvas: function (width, height) {
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      this.source = canvas.toDataURL()
    },
    getSizes: function () {
      if (this.isSvg) return
      const img = this.$refs.image
      this.imgWidth = img.width + 'px'
    },
    warnMissingImages: function () {
      if (this.ENV.name !== 'production') {
        if ((!this.imgSrc2x || !this.webpSrc || !this.webpSrc2x) && !this.isSvg) {
          console.warn(`Missing one or more image files for ${this.imgSrc}`)
        }
      }
    }
  },
  destroyed: function () {
    if (this.observer) this.observer.disconnect()
  }
}
</script>

<template>
  <div :inert="modalOpen">
    <div v-show="showNav" class="nav-wrap" :class="{'scrolled': scrolled, 'nav-minimal': minimal}">
      <div v-if="showHelloBar" class="hello-bar-block"></div>
      <nav-teams v-if="menuType === 'teams'"></nav-teams>
      <nav-tb-by-mcafee v-else-if="menuType === 'tb-by-mcafee'"></nav-tb-by-mcafee>
      <nav v-else id="main-header" :class="{'tron': sale.isLive && sale.name === 'cyber' && current === 'home'}">
        <hello-bar v-if="showHelloBar" :class="{'tron': sale.isLive && sale.name === 'cyber'}"></hello-bar>
        <div class="nav-inner clearfix complex">
          <div class="top-bar">
            <router-link :to="{ name: 'home'}" class="logo">
              <img class="hidden-xs" src="~@/images/logo-grey.svg" alt="TunnelBear">
              <img class="visible-xs" src="~@/images/t-grey.svg" alt="TunnelBear">
            </router-link>
            <button id="mobile-menu" @click="toggleMobileMenu()" class="plain" aria-label="menu"></button>
          </div>
          <div class="drawer" :class="{'mobile-open': mobileMenuExpanded}">
            <div class="screen" @click="toggleMobileMenu()" aria-hidden="true"></div>
            <div id="nav-items">
              <div class="mobile-controls">
                <button @click="toggleMobileMenu()" class="plain link right close" aria-label="close navigation"></button>
              </div>
              <nav-team-admin v-if="menuType === 'teamAdmin'" @logout="logout()"></nav-team-admin>
              <nav-team-member v-else-if="menuType === 'teamMember'" :current="current" @logout="logout()" @closeNav="toggleMobileMenu()"></nav-team-member>
              <nav-paid v-else-if="menuType === 'paid'" :current="current" @logout="logout()" @closeNav="toggleMobileMenu()"></nav-paid>
              <nav-free v-else-if="menuType === 'free'" :current="current" @logout="logout()" @closeNav="toggleMobileMenu()"></nav-free>
              <div v-else class="nav-menu">
                <div class="nav-items-wrap">
                  <div class="nav-item" :class="{'current': current === 'pricing'}" @click="toggleMobileMenu()">
                    <router-link :to="{ name: 'pricing' }">Plans</router-link>
                  </div>
                  <nav-link-vpn
                    :current="current === 'what-is-vpn'"
                    @dropdownClicked="toggleMobileMenu()"
                  ></nav-link-vpn>
                   <div class="nav-item">
                    <router-link :to="{ name: 'download' }">Download</router-link>
                  </div>
                  <div class="nav-item">
                    <a href="https://help.tunnelbear.com/hc/en-us">Help</a>
                  </div>
                  <div class="nav-item" :class="{'current': current === 'overview'}" @click="toggleMobileMenu()">
                    <router-link :to="{ name: 'overview' }">{{loggedIn ? 'My&nbsp;Account' : 'Log In'}}</router-link>
                  </div>
                </div>
                <div class="nav-item bottom" @click="toggleMobileMenu()">
                  <a v-if="is.ios()" :href="external.downloadLinks.ios">
                    <img class="mobile-btn app-store" src="~@/images/app-store.svg" alt="Download on the App Store">
                  </a>
                  <router-link v-else :to="{ name: 'pricing' }" class="btn" :class="{'hollow': current === 'pricing'}">Get&nbsp;TunnelBear</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-inner minimal">
          <!-- .nav-inner.minimal is set to {display:none} but is overridden by {display:flex} when inside .nav-minimal container -->
          <div :class="['top-bar', {'custom-cta': customCta}]">
            <router-link :to="{ name: 'home'}" class="logo">
              <img src="~@/images/tb-logo-grey.svg" alt="TunnelBear">
            </router-link>
            <a v-if="customCta" class="btn custom-cta-link" :href="customCta.link">{{customCta.copy}}</a>
          </div>
        </div>
      </nav>
      <div v-if="is.mobile() && !isTBMcafeeLandingPage" class="mobile-cta-wrap" :class="{'show': mobileScrolled}" v-show="!mobileMenuExpanded">
        <hello-bar v-if="showHelloBar"
          :hideCtaButton="hideCtaButton"></hello-bar>
        <div v-else class="mobile-cta-nav">
          <template v-if="menuType === 'paid' || is.ios()">
            <img v-if="is.ios()" class="icon" src="./images/ios-icon.svg" alt="">
            <img v-else class="icon" src="./images/android-icon.svg" alt="">
            <div class="info">
              <div v-if="is.ios()">
                <p><strong>Get TunnelBear</strong></p>
                <p>For iPhone &amp; iPad</p>
              </div>
              <div v-else>
                <p><strong>Get TunnelBear</strong></p>
                <p>For Android</p>
              </div>
            </div>
            <a class="btn hollow" :href="is.ios() ? external.downloadLinks.ios : external.downloadLinks.android">Get</a>
          </template>
          <template v-else>
            <router-link :to="{ name: 'home'}" class="logo">
              <img src="~@/images/t-grey.svg" alt="TunnelBear">
            </router-link>
            <a v-if="menuType === 'teams'" href="/team/create" class="btn">Try it free</a>
            <router-link v-else class="btn" :to="{name: 'pricing'}">Get TunnelBear</router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helloBar from '@/components/hello-bar/hello-bar'
import services from '@/services/services.js'
import Cookies from 'js-cookie'
import is from 'is_js'
import navTeams from './components/nav-teams/nav-teams'
import navTbByMcafee from './components/nav-tb-by-mcafee/nav-tb-by-mcafee.vue'
import navFree from './components/nav-free/nav-free'
import navPaid from './components/nav-paid/nav-paid'
import navTeamAdmin from './components/nav-team-admin/nav-team-admin'
import navTeamMember from './components/nav-team-member/nav-team-member'

import navLinkApps from './components/nav-link-apps/nav-link-apps'
import navLinkVpn from './components/nav-link-vpn/nav-link-vpn'

export default {
  name: 'navigation',
  props: {
    minimal: Boolean,
    hideCtaButton: Boolean,
    customCta: {
      type: [Object, Boolean],
      default: false
    }
  },
  components: {
    'hello-bar': helloBar,
    'nav-teams': navTeams,
    'nav-tb-by-mcafee': navTbByMcafee,
    'nav-free': navFree,
    'nav-paid': navPaid,
    'nav-team-admin': navTeamAdmin,
    'nav-team-member': navTeamMember,
    'nav-link-apps': navLinkApps,
    'nav-link-vpn': navLinkVpn
  },
  data () {
    return {
      is,
      mobileMenuExpanded: false,
      scrolled: false,
      mobileScrolled: false
    }
  },
  computed: {
    isTBMcafeeLandingPage: function () {
      if (this.$route.name === 'tb-by-mcafee' || this.$route.name === 'tb-by-mcafee-checkout') {
        return true
      }
      return false
    },
    modalOpen: function () {
      return this.$store.getters.modalOpen
    },
    sale: function () {
      return this.$store.state.pricingService.sale
    },
    showHelloBar: function () {
      // show the helloBar 1) for non-paid users WHEN  2) we're on the pricing, home, or apps pages, or we're on the checkout page and the route's couponCode query matches the sale's couponCode AND 3) the sale is live

      // only when there's a sale
      if (!this.sale.isLive) {
        return false
      }

      // only for non-paid users
      if (!(this.menuType === 'public' || this.menuType !== 'free')) {
        return false
      }

      // show for these pages
      if (
        this.$route.name === 'pricing' ||
        this.$route.name === 'home' ||
        this.$route.path.indexOf('apps') > -1
      ) {
        return true
      }

      // i think we actually don't want to show on the checkout page????
      // return this.$route.name === 'checkout' && this.sale.couponRoute.query.couponCode === this.$route.query.couponCode

      // in all other cases, hide it
      return false
    },
    current: function () {
      return this.$route.name
    },
    loggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    menuType: function () {
      const session = this.$store.getters.userData
      let menuType = 'public'
      if (this.$route.name === 'teams') {
        menuType = 'teams'
      } else if (this.isTBMcafeeLandingPage) {
        menuType = 'tb-by-mcafee'
      } else if (session.teamRole) {
        if (session.teamRole === 'ADMIN') {
          menuType = 'teamAdmin'
        } else {
          menuType = 'teamMember'
        }
      } else if (session.plan) {
        if (session.plan === 'free') {
          menuType = 'free'
        } else {
          menuType = 'paid'
        }
      }
      return menuType
    },
    showNav: function () {
      return (this.$store.getters.isLoggedIn && this.$store.getters.userDataRequested) ||
        !this.$store.getters.isLoggedIn
    }
  },
  created: function () {
    window.addEventListener('scroll', this.calculateNavState)
  },
  methods: {
    toggleMobileMenu: function () {
      this.mobileMenuExpanded = !this.mobileMenuExpanded
      this.$emit('toggleScroll', this.mobileMenuExpanded)
    },
    calculateNavState: function () {
      this.scrolled = services.windowScrollTop() > 50
      this.mobileScrolled = services.windowScrollTop() > 450
    },
    logout: function () {
      this.$store.dispatch('logout').then((response) => {
        this.$router.push({ name: 'home' })
      })
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.calculateNavState)
  }
}
</script>

<style lang="stylus" src="./nav.styl">

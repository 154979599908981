<template>
  <div class="container" :class="{ expanded: expanded }">
    <button @click="expanded = !expanded" class="faq-title btn plain dark">
      <slot name="title"></slot>
      <img :class="{ 'expanded': expanded }" src="./images/plus.png" :alt="expanded ? 'minimize list item' : 'expand list tem'">
    </button>
    <transition name="slide-fade">
      <div v-if="expanded" class="faq-content">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'list-item',
  data () {
    return {
      expanded: false
    }
  }
}
</script>

<style scoped lang="stylus" src="./list-item.styl">

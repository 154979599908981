<template>
  <div>
    <transition name="fade">
      <div v-if="!accepted" class="view-wrap">
        <div class="view-content">
          <section class="banner-wrap no-padding" :class="{ 'visible': !accepted }">
            <div class="banner-inner section-inner">
              <div class="banner-content">
                <div class="text-img">
                  <img src="./images/cookies.svg" alt="cookies">
                  <div class="txt">
                    <p class="small-copy">
                      Rawr! We use cookies to help operate our website and make your experience better. By continuing on our site, you're giving us consent to feed your browser cookies. <router-link :to="{ 'name': 'privacy-policy', query: $route.query }">Learn more about how we use cookies</router-link>.
                    </p>
                    <button @click.once="accept()">Got it</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Cookies from 'js-cookie'

export default {
  name: 'cookie-banner',
  data () {
    return {
      hidden: false,
      watcher: null,
      activeUser: !!Cookies.get('ac'),
      query: {},
      urlData: {}
    }
  },
  created: function () {
    this.resolveQuery()
    window.addEventListener('click', this.handleClicks)
    if (this.activeUser) {
      this.setMkt()
    } else {
      this.watcher = this.$store.watch((state) => state.activeUser, (clicked) => {
        if (clicked === true) {
          this.activeUser = clicked
          this.setMkt()
        }
      })
    }
  },
  computed: {
    accepted: function () {
      return !!Cookies.get('ac') || this.activeUser
    }
  },
  methods: {
    resolveQuery: function () {
      // we use window.location.search because the route value doesn't update on pageload.
      this.query = window.location.search
        .slice(1)
        .split('&')
        .map(p => p.split('='))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

      this.urlData = {
        utm_source: this.query.utm_source,
        utm_medium: this.query.utm_medium,
        utm_campaign: this.query.utm_campaign,
        utm_term: this.query.utm_term,
        utm_content: this.query.utm_content,
        aff_id: this.query.aff_id,
        aff_integration: this.query.trans_id ? 'hasoffers' : (this.query.cjevent ? 'cj' : ''),
        aff_trans_id: this.query.cjevent || this.query.trans_id,
        aff_offer_id: this.query.offer_id,
        clickid: this.query.clickid,
        campaignid: this.query.campaignid,
        feeditemid: this.query.feeditemid,
        adgroupid: this.query.adgroupid,
        keyword: this.query.keyword,
        matchtype: this.query.matchtype,
        creative: this.query.creative,
        adposition: this.query.adposition
      }
    },
    accept: function () {
      this.$store.dispatch('activeUser')
      Cookies.set('ac', true, {
        expires: 365,
        secure: true
      })
    },
    setMkt: function () {
      if (!this.$store.getters.isLoggedIn && (this.urlData.utm_source || this.urlData.aff_id)) {
        Cookies.set('tb_mkt', JSON.stringify(this.urlData), {
          expires: 1,
          secure: true
        })
      }
      if (this.query.ref_id) {
        Cookies.set('tb_ref', this.query.ref_id, {
          expires: 1,
          secure: true
        })
      }
    }
  },
  beforeDestroy: function () {
    // unwatch this function
    if (this.watcher) {
      this.watcher()
    }
  }
}
</script>

<style scoped lang="stylus" src="./cookie-banner.styl" >

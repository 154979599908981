<template>
  <div>
    <form class="form-area" v-on:submit.prevent="submit">
      <div class="form-section">
        <announce addClass="full-width">
          <p class="field-error" :class="{ 'show-error' : showError }">
            {{ otherError || "Grrr... Is that email&nbsp;address&nbsp;valid?" }}
          </p>
        </announce>
        <dynamic-input name="email" type="text" @model="email = $event" required>Email</dynamic-input>
      </div>
    </form>
    <submit-button :finished="finished" :submitting="submitting" @submit="submit">Submit</submit-button>
  </div>
</template>

<script>
import dynamicInput from '@/components/dynamic-input/dynamic-input'
import submitButton from '@/components/submit-button/submit-button'
import is from 'is_js'

export default {
  name: 'forgot',
  components: {
    'dynamic-input': dynamicInput,
    'submit-button': submitButton
  },
  data () {
    return {
      email: '',
      error: false,
      submitted: false,
      submitting: false,
      finished: false,
      otherError: ''
    }
  },
  computed: {
    notEmail: function () {
      return is.not.email(this.email)
    },
    showError: function () {
      return this.submitted && (this.notEmail || this.otherError)
    }
  },
  methods: {
    submit: async function () {
      this.otherError = ''
      this.submitted = true
      if (this.notEmail) return
      this.submitting = true
      const data = { email: this.email }
      try {
        await this.$store.dispatch('requestResetPassword', data)
        this.submitting = false
        this.finished = true
        this.$emit('success', this.email)
      } catch (error) {
        this.submitting = false
        this.finished = false
        this.otherError = error
      }
    }
  }
}
</script>

<style scoped lang="stylus" src="./forgot-form.styl" >

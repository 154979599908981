<template>
  <div class="view-wrap">
    <modal ref="modal" :closeable="true" size="large" labelID="countries-desc">
      <h4 id="countries-desc" class="h5">Servers in {{ tunnels.length }} countries</h4>
      <div class="countries-container">
        <country-flags></country-flags>
      </div>
    </modal>
    <div class="view-content" :inert="modalOpen">
      <section id="hero" class="bg-light">
        <div class="section-inner">
          <div class="container">
            <h1 class="text-center">
              What is a VPN?
              <span>{{year}} Guide</span>
            </h1>
            <img src="./images/bear-globe.svg" alt="TunnelBear network spans the globe">
          </div>
        </div>
      </section>
      <section class="bg-regular smaller">
        <div class="section-inner">
          <div class="container">
            <div class="element medium centered">
              <p>
                If your device is connected to the internet, your internet service provider can see everything you do.
                Every search. Every file you download. Everything. To protect their privacy, more people are using virtual
                private networks, or VPN for short, to stop companies from tracking what they do online.
              </p>
              <p>
                To help put an end to the misinformation surrounding VPN, we'll explain what a VPN is, how it works,
                why you need one, and what a VPN can do for you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="contents" class="bg-regular smaller">
        <div class="section-inner">
          <div class="container">
            <div class="element medium centered">
              <h2 class="text-center">VPN Overview: Table of Contents</h2>
              <div class="table">
                <a href="#what-is-a-vpn" @click="scrollToHash('#what-is-a-vpn')">What is a VPN?</a>
                <a href="#why-do-i-need" @click="scrollToHash('#why-do-i-need')">Why do I need a VPN?</a>
                <a href="#how-it-works" @click="scrollToHash('#how-it-works')">What does a VPN do and how does it work?</a>
                <a href="#vpn-client" @click="scrollToHash('#vpn-client')">What does a VPN client do exactly?</a>
                <a href="#how-to-install" @click="scrollToHash('#how-to-install')">How do I install a VPN?</a>
                <a href="#how-to-use" @click="scrollToHash('#how-to-use')">How do I use my VPN once it has been set up?</a>
                <a href="#vpn-faq" @click="scrollToHash('#vpn-faq')">VPN FAQ</a>
                <a href="#why-tunnelbear" @click="scrollToHash('#why-tunnelbear')">Why TunnelBear is the right VPN solution for you</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="what" class="bg-regular smaller">
        <span class="anchor" id="what-is-a-vpn"></span>
        <div class="section-inner">
          <div class="container">
            <div class="element medium centered">
              <h2 class="text-center">What is a VPN?</h2>
              <p>
                A VPN is an app that keeps your internet connection private, whether you're connecting to unsafe public
                Wi-Fi or your network at home. Having a layer of security that blocks people from watching you browse helps
                keep you safe online, no matter where you connect from.
              </p>
              <p>
                Virtual private networks protect you by creating an encrypted "tunnel" that all of your device's data
                travels through on its way to the internet. Encryption turns words and data, like text files, into a
                secret code. If someone tries to read encrypted data without the password, they'll see random gibberish.
              </p>
              <div class="caption-img text-center">
                <img class="layout-img" srcset="./images/encryption.png 1x, ./images/encryption@2x.png 2x" src="./images/encryption@2x.png" alt="VPN encryption turning contracts, credit cards, banking information and personal data into secret code">
                <p>TunnelBear makes your data unreadable between you and its destination</p>
              </div>
              <p>
                A VPN acts as your gateway for accessing the internet privately. VPN software can be installed on
                most popular devices, like your phone, laptop and desktop. One important thing to remember is a VPN
                provider isn't the same as an internet service provider. You'll still need an ISP to connect to the
                internet, your VPN runs on top of that connection.
              </p>
            </div>
            <p class="pull-quote text-center" aria-hidden="true">A VPN encrypts your internet connection to keep your online activity private on any&nbsp;network.</p>
            <h3 class="h4 text-center">When you connect to&nbsp;a&nbsp;VPN</h3>
            <div class="row list">
              <div class="col col-12 col-md-4 text-center">
                <img-item>
                  <template slot="img"><img src="./images/connect/browse.svg" alt="VPN blocks online spying"></template>
                  <template slot="content">
                    <p>Your browsing is private, so you can't be easily tracked online</p>
                  </template>
                </img-item>
              </div>
              <div class="col col-12 col-md-4 text-center">
                <img-item>
                  <template slot="img"><img src="./images/connect/unblock.svg" alt="Tunnel around the world"></template>
                  <template slot="content">
                    <p>Your connection looks like it's coming from another country, which unblocks some websites</p>
                  </template>
                </img-item>
              </div>
              <div class="col col-12 col-md-4 text-center">
                <img-item>
                  <template slot="img"><img src="./images/connect/encrypted.svg" alt="Encrypt your browsing"></template>
                  <template slot="content">
                    <p>Your internet connection is encrypted, keeping the information you send and receive private</p>
                  </template>
                </img-item>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="why" class="bg-regular smaller">
        <span class="anchor" id="why-do-i-need"></span>
        <div class="section-inner">
          <div class="container">
            <div class="element medium centered">
              <h2 class="text-center">Why do I need a VPN?</h2>
              <p>
                The reasons why you need a VPN are personal, and a little different for everyone. Some people use VPN to bypass app restrictions
                and stay in touch with loved ones while traveling in heavily censored countries. Other people use VPN to stay secure on public Wi-Fi.
                Some people, just like knowing their internet service provider can't see what they're browsing every time they go online.
              </p>
              <p>
                While the benefits of using VPN are just starting to go mainstream, it wasn't long ago that people had trouble seeing the
                importance of antivirus. As hacking and viruses became regular news stories, people realized how dangerous just browsing a website could
                be. <a href="https://www.tunnelbear.com/blog/always-use-a-vpn/" target="_blank">Loss of privacy</a> has become the new "virus,"
                and while most devices have some type of virus protection, very few have any kind of privacy protection.
              </p>
              <p>
                Just like you need protection from viruses, you need to protect yourself from privacy threats. Using a VPN wraps your
                <a href="https://www.tunnelbear.com/blog/stronger-encryption/" target="_blank">browsing in a layer of encryption</a>, making it harder
                for people to spy on you online.
              </p>
            </div>
            <p class="pull-quote text-center" aria-hidden="true">You need a VPN because they provide security and protect your&nbsp;privacy.</p>
            <p class="text-center">Ready to start using a VPN? <router-link :to="{name: 'pricing'}">Get&nbsp;TunnelBear&nbsp;today!</router-link></p>
          </div>
        </div>
      </section>
      <section id="reasons" class="bg-regular smaller">
        <div class="section-inner">
          <div class="container">
            <h2 class="text-center">Top reasons people use&nbsp;VPN</h2>
            <div class="row list">
              <div class="col col-12 col-md-4">
                <list-box :initialExpand="true">
                  <template slot="img"><img src="./images/reasons/ip.svg" alt="Bear knocking over street sign"></template>
                  <template slot="title"><h3 class="h5">Change your IP address</h3></template>
                  <template slot="content">
                    <p>
                      Your IP address is kind of like a phone number that tells websites where you are and how to connect to your device.
                      TunnelBear gives you a new IP, so all of your traffic looks like it's coming from somewhere else.
                    </p>
                  </template>
                </list-box>
              </div>
              <div class="col col-12 col-md-4">
                <list-box>
                  <template slot="img"><img src="./images/reasons/tracking.svg" alt="Bear blocking path of search hound"></template>
                  <template slot="title"><h3 class="h5">Block online tracking</h3></template>
                  <template slot="content">
                    <p>
                      Advertisers invade your online privacy by targeting your IP for ads. By changing your IP address, TunnelBear blocks
                      common ways advertising trackers follow you around the internet.
                    </p>
                  </template>
                </list-box>
              </div>
              <div class="col col-12 col-md-4">
                <list-box>
                  <template slot="img"><img src="./images/reasons/wifi.svg" alt="Bear protecting woman at coffee shop"></template>
                  <template slot="title"><h3 class="h5">Stay safe on public WiFi</h3></template>
                  <template slot="content">
                    <p>
                      People love public Wi-Fi, but so do hackers. With simple tools, they can capture passwords and logins as they pass
                      through a shared Wi-Fi point. Keep your accounts safe by encrypting your connection with TunnelBear.
                    </p>
                  </template>
                </list-box>
              </div>
            </div>
            <div class="row">
              <div class="col col-12 col-md-4">
                <list-box>
                  <template slot="img"><img src="./images/reasons/home.svg" alt="Bear tunneling across the world"></template>
                  <template slot="title"><h3 class="h5">Stay connected to home</h3></template>
                  <template slot="content">
                    <p>
                      Don't miss out on current events or local news when you're travelling. With servers in <button class="btn plain link" @click="openModal()">{{ tunnels.length }} countries</button>, TunnelBear
                      can help you stay connected to home as if you were&nbsp;there.
                    </p>
                  </template>
                </list-box>
              </div>
              <div class="col col-12 col-md-4">
                <list-box>
                  <template slot="img"><img src="./images/reasons/stream.svg" alt="Spedometer showing speeds from turtle, to rabbit to grizzly bear"></template>
                  <template slot="title"><h3 class="h5">Stream video faster</h3></template>
                  <template slot="content">
                    <p>
                      Internet service providers regularly throttle services they compete with, but with TunnelBear, you can avoid throttling
                      by keeping your browsing private from your service&nbsp;provider.
                    </p>
                  </template>
                </list-box>
              </div>
              <div class="col col-12 col-md-4">
                <list-box>
                  <template slot="img"><img src="./images/reasons/censorship.svg" alt="Bear escaping through a cage"></template>
                  <template slot="title"><h3 class="h5">Bypass censorship</h3></template>
                  <template slot="content">
                    <p>
                      More governments are trying to censor news and communications outside their borders. TunnelBear can help bypass censorship
                      by connecting to a server in another country, so you can get the information you&nbsp;need.
                    </p>
                  </template>
                </list-box>
              </div>
            </div>
            <div class="row">
              <div class="col col-12 col-md-4">
                <list-box>
                  <template slot="img"><img src="./images/reasons/travel.svg" alt="Bear protecting beach goers"></template>
                  <template slot="title"><h3 class="h5">Travel securely</h3></template>
                  <template slot="content">
                    <p>
                      Don't trust the Wi-Fi in your hotel? TunnelBear's Grizzly-grade encryption lets you safely check your email, bank account,
                      book flights and more—all without risking your personal information.
                    </p>
                  </template>
                </list-box>
              </div>
              <div class="col col-12 col-md-4">
                <list-box>
                  <template slot="img"><img src="./images/reasons/privacy.svg" alt="Bear head sticking out of cellphone"></template>
                  <template slot="title"><h3 class="h5">Protect your online privacy</h3></template>
                  <template slot="content">
                    <p>
                      Most websites ignore your Human Right to privacy. Whether it's your internet service provider monitoring your connection
                      or advertisers tracking you, exercising your right to privacy is becoming more difficult. With TunnelBear VPN, all of your
                      browsing is encrypted so no one else can see what you're&nbsp;doing.
                    </p>
                  </template>
                </list-box>
              </div>
              <div class="col col-12 col-md-4">
                <list-box>
                  <template slot="img"><img src="./images/reasons/peer.svg" alt="Lightening fast bear"></template>
                  <template slot="title"><h3 class="h5">Peer-To-Peer</h3></template>
                  <template slot="content">
                    <p>
                      Internet service providers regularly slow, and even block, torrent connections on their networks. TunnelBear protects you
                      from disconnects and blocks ISP from seeing your traffic, so they can't slow&nbsp;it&nbsp;down.
                    </p>
                  </template>
                </list-box>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="how" class="bg-regular">
        <span class="anchor" id="how-it-works"></span>
        <div class="section-inner">
          <div class="container">
            <div class="element medium centered">
              <h2 class="text-center">What does a VPN do and how does it&nbsp;work?</h2>
              <h3 class="h5">Security</h3>
              <p>
                A VPN encrypts your connection and protects your personal data from being intercepted and read by attackers. Typical things attackers
                try to steal are passwords, credit card information, and private messages.
              </p>
              <h3 class="h5">Privacy</h3>
              <p>
                Using a VPN adds a layer of privacy to your online life. A VPN prevents your ISP, employer, or network owner from logging all the
                sites you visit, and stops websites from following your IP address around the internet.
              </p>
              <h3 class="h5">Access</h3>
              <p>
                VPN provide access to an open and unrestricted internet. By connecting to servers in other countries, VPN help you bypass government
                censorship bans on news sites, social networks, games, or other websites you&nbsp;love.
              </p>
              <p>
                VPN work by creating a secure tunnel that protects your device's connection to the internet. That protection is a layer of encryption,
                or coded language, only your VPN app and the server understand. With an encrypted connection, no one can monitor your online activity.
                If someone tried to track what you were doing, they would only see a random series of numbers and&nbsp;letters.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="with-tunnelbear" class="bg-light">
        <div class="section-inner">
          <div class="container">
            <div class="element xs centered text-center">
              <h2>With TunnelBear</h2>
              <p>Your data is protected inside an encrypted tunnel from ISPs, network owners, and hackers.</p>
            </div>
            <img class="layout-img desktop" srcset="./images/encrypted-tunnel.png 1x, ./images/encrypted-tunnel@2x.png 2x" src="./images/encrypted-tunnel@2x.png" alt="TunnelBear sends your browsing through an encrypted tunnel, keeping you safe from ISP and hackers">
            <img class="layout-img mobile" srcset="./images/encrypted-tunnel-mobile.png 1x, ./images/encrypted-tunnel-mobile@2x.png 2x" src="./images/encrypted-tunnel-mobile@2x.png" alt="TunnelBear sends your browsing through an encrypted tunnel, keeping you safe from ISP and hackers">
          </div>
        </div>
      </section>
      <section id="client" class="bg-regular">
        <span class="anchor" id="vpn-client"></span>
        <div class="section-inner">
          <div class="container">
            <div class="element medium centered">
              <h2 class="text-center">What does a VPN client do exactly?</h2>
              <p>
                To use VPN software, you need something called a VPN client. "Client" is really just another term for app, but the difference is that
                clients act as a gateway between your device and the service you're trying to connect to. Clients aren't a standalone app.
              </p>
              <p>
                Your device connects to the VPN server through the VPN client. Once you're connected, the client gives you access to the VPN service.
              </p>
              <p>
                VPN aren't the only software that use clients. Some well-known examples of clients are online games like Fortnite or the Adobe Creative Suite.
              </p>
            </div>
            <p class="pull-quote text-center" aria-hidden="true">
              ...clients act as a gateway between your device and&nbsp;the&nbsp;service...
            </p>
          </div>
        </div>
      </section>
      <section id="install" class="bg-light">
        <span class="anchor" id="how-to-install"></span>
        <div class="section-inner">
          <div class="container">
            <h2 class="text-center">How do I install a VPN?</h2>
            <div class="row list">
              <div class="col col-12 col-md-4">
                <img-item>
                  <template slot="img"><img src="./images/install/download-tunnelbear-apps.svg" alt="TunnelBear App Icon"></template>
                  <template slot="content">
                    <h3 class="h6">Download the app</h3>
                    <p>TunnelBear apps are available for PC, Mac, iPhone and Android.</p>
                  </template>
                </img-item>
              </div>
              <div class="col col-12 col-md-4">
                <img-item>
                  <template slot="img"><img src="./images/install/create-your-tunnelbear-username-password.svg" alt="Username and password form fields"></template>
                  <template slot="content">
                    <h3 class="h6">Create an account</h3>
                    <p>Use your email and create a password.</p>
                  </template>
                </img-item>
              </div>
              <div class="col col-12 col-md-4">
                <img-item>
                  <template slot="img"><img src="./images/install/toggle-tunnelbear-on.svg" alt="Toggle switch to turn TunnelBear on"></template>
                  <template slot="content">
                    <h3 class="h6">Connect</h3>
                    <p>Tap the button to connect to the&nbsp;VPN.</p>
                  </template>
                </img-item>
              </div>
            </div>
            <div class="text-center btn-wrap">
              <router-link class="btn btn-lg" :to="{name: 'pricing'}">Get Started</router-link>
            </div>
          </div>
        </div>
      </section>
      <section id="use" class="bg-regular">
        <span class="anchor" id="how-to-use"></span>
        <div class="section-inner">
          <div class="container">
            <div class="element medium centered">
              <h2 class="text-center">How do I use my VPN once it has been&nbsp;set&nbsp;up?</h2>
              <p>
                Once your VPN has been set up, just pick a country and turn it on. The VPN runs in the background as you browse. The most
                noticeable difference is that you should have access to websites you didn't have access to before.
              </p>
              <p>
                Most VPN providers offer servers in different countries. This is how people regularly bypass censorship and unblock
                websites. If you're trying to access something that's blocked in your country, connecting to a server in another country should give you access.
              </p>
              <div class="caption-img text-center">
                <img v-if="pageLoaded" class="layout-img" srcset="./images/tunnelbear-dropdown-menu.png 1x, ./images/tunnelbear-dropdown-menu@2x.png 2x" src="./images/tunnelbear-dropdown-menu@2x.png" alt="TunnelBear VPN App dropdown menu showing some server locations: United States of America, United Kingdom, Canada, Germany, Japan, Australia, France, Italy, Netherlands, Sweden">
                <p>Choose a country and switch TunnelBear on,&nbsp;that's&nbsp;it!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq" class="bg-regular smaller">
        <span class="anchor" id="vpn-faq"></span>
        <div class="section-inner">
          <div class="container">
            <div class="content">
              <h2 class="text-center">VPN FAQ</h2>
              <ul class="faq">
                <li>
                  <list-item>
                    <template slot="title">Do I just browse like I normally would?</template>
                    <template slot="content">
                      <p>
                        Yup. Just turn TunnelBear on and use the internet like you normally would. TunnelBear works in the background
                        to protect your connection from eavesdroppers, so you shouldn't notice any difference in your browsing experience. Check out more information on how to use TunnelBear <a href="https://help.tunnelbear.com/hc/en-us/articles/360030300111" target="_blank">here</a>.
                      </p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Is my online privacy worth the&nbsp;cost?</template>
                    <template slot="content">
                      <p>
                        That entirely depends on how you feel about letting strangers at for-profit companies know the most intimate
                        details of your life. In many countries, it's legal for internet service providers to record, package and sell
                        your private information to any company interested in buying it.
                      </p>
                      <p>
                        Once your information is out there, it's sold multiple times until it eventually falls into the hands of ad-tech
                        companies. With little to no oversight on how data is stored and protected, hackers can steal millions of users'
                        information with little to no effort.
                      </p>
                      <p>
                        The cost of privacy is different for everyone. For some people, voluntarily giving away some personal information
                        is a fair exchange for a "free" service. For others, not knowing who has their data or what they're doing with it
                        is more than enough reason to use a VPN.
                      </p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">How do I know that TunnelBear is working and keeping me secure?</template>
                    <template slot="content">
                      <p>
                        To check if TunnelBear is working, turn the app on, then visit <a href="https://bearsmyip.com" target="_blank">Bearsmyip</a>. If the website shows that you're
                        connected to a server in the country you've chosen, you're secure! You should also see a green banner that says,
                        "Your location is private and encrypted with a Bear."
                      </p>
                      <p>If you are having issues connecting, please visit our connection help article.</p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Does TunnelBear store or log any information/data with my VPN?</template>
                    <template slot="content">
                      <p>
                        TunnelBear will never log, or store, <router-link :to="{ 'name': 'privacy-policy', hash: '#1.3' }" target="_blank">any of your usage data</router-link>. To run the service, we do need an email address we can
                        reach you at – to confirm your account – and a payment method after you've upgraded.
                      </p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Are there legal implications if I use VPN?</template>
                    <template slot="content">
                      <p>
                        In most countries, using a VPN is perfectly legal. However, in several places, like China and Russia, if you're caught
                        using a VPN that isn't government-approved, you can be fined.
                      </p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Will using a VPN slow down my connection?</template>
                    <template slot="content">
                      <p>
                        With regular use, you shouldn't notice a difference in speed while using TunnelBear. Latency can be an issue if
                        you're connected to a server on the other side of the world though. If you have speed issues, <a href="https://help.tunnelbear.com/hc/en-us/articles/360006839792" target="_blank"> please see our
                        troubleshooting article</a> to help find the cause.
                      </p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">I have nothing to hide, so why should I use a VPN?</template>
                    <template slot="content">
                      <p>
                        Having nothing to hide and giving up your privacy are two very different things. Just because you're a law-abiding
                        citizen, doesn't mean you'd keep your curtains open while you're changing. VPN protect your right to privacy from
                        people that want to know your most intimate thoughts and personal moments.
                      </p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Am I 100% secure with a VPN?</template>
                    <template slot="content">
                      <p>
                        VPN make it harder for attackers to find you and threaten your online security. They won't make you anonymous or protect
                        you from every online threat, but no single product can do that. There are a lot of online threats the require specific
                        tools for each one. VPN are one of those tools, like an adblocker or antivirus.
                      </p>
                      <p>Basically, if any VPN provider claims to give you "complete anonymity and security," they're lying.</p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">How do I know if my VPN connection is working?</template>
                    <template slot="content">
                      <p>
                        Visiting <a href="https://bearsmyip.com" target="_blank">Bearsmyip</a> will quickly show you if you're securely connected to the TunnelBear network. If you're having issues connecting,
                        please visit our connection help article.
                      </p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">What is a DNS leak and will I ever experience one with TunnelBear VPN?</template>
                    <template slot="content">
                      <p>
                        A DNS leak happens when your VPN is on, but you connect to a website through your internet service provider's DNS servers outside
                        your tunnel.
                      </p>
                      <p>
                        You will never experience a DNS leak with TunnelBear because we have our own secure DNS servers. When you connect to TunnelBear,
                        your DNS requests happen inside your tunnel, like all your other data, keeping your searches safe from prying eyes.
                      </p>
                      <p>If you have any questions about DNS leaks, please see our <a href="https://help.tunnelbear.com/hc/en-us/articles/360007120871-Does-TunnelBear-protect-against-DNS-leaks-" target="_blank">help article</a>, or contact our friendly Support Bears.</p>
                    </template>
                  </list-item>
                </li>
                <li>
                  <list-item>
                    <template slot="title">Where are the TunnelBear servers located?</template>
                    <template slot="content">
                      <p>TunnelBear has servers in <button class="btn plain link" @click="openModal()">{{ tunnels.length }} countries</button>.</p>
                    </template>
                  </list-item>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="tunnelbear" class="bg-regular">
        <span class="anchor" id="why-tunnelbear"></span>
        <div class="section-inner">
          <div class="container">
            <div class="element medium centered">
              <h2 class="text-center">Why TunnelBear is the right VPN solution&nbsp;for&nbsp;you</h2>
              <p>
                TunnelBear works quietly in the background to protect all of your devices. With apps for phones, laptops, desktops and browser
                extensions, you can secure all of your data, no matter what device you're on, or where you are.
              </p>
              <p>
                Using TunnelBear is easy. Once you've installed TunnelBear, you just pick a country and turn it on. All of
                the complicated work is done for you.
              </p>
            </div>
            <div class="caption-img text-center">
              <img v-if="pageLoaded" class="layout-img" srcset="./images/tunnelbear-apps-devices.png 1x, ./images/tunnelbear-apps-devices@2x.png 2x" src="./images/tunnelbear-apps-devices@2x.png" alt="Collection of devices TunnelBear VPN is compatible with: Windows, macOS, Android and iOS">
              <p>Get TunnelBear on your iPhone, iPad, Android Phone or Tablet, Mac or PC.</p>
            </div>
            <p class="pull-quote text-center" aria-hidden="true">
              Browse from anywhere with peace of mind, knowing that your online activity is more private from
              hackers, ISPs, and&nbsp;advertisers.
            </p>
            <div class="element medium centered">
              <p>
                Flexible pricing lets you decide whether you would like to be billed monthly or annually (there's a discount for
                annual memberships).
              </p>
              <p>
                TunnelBear does not, and will never, log your usage or connection information. Privacy is a Universal Human
                Right that we respect and fight for. Our <router-link :to="{name: 'privacy-policy'}">Privacy Policy</router-link> outlines our stance on privacy and customer data.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="download" class="bg-alt">
        <div class="section-inner">
          <div class="container text-center">
            <div class="element medium copy centered">
              <img src="@/images/tb-icon.svg" alt="TunnelBear App Icon">
              <h2>Download TunnelBear to start browsing privately&nbsp;today!</h2>
              <router-link class="btn btn-lg" :to="{name: 'pricing'}">Get TunnelBear</router-link>
            </div>
          </div>
        </div>
      </section>
      <ai-chat-bot v-if='!isAiBlockedCountry'/>
    </div>
  </div>
</template>

<script>
import listItem from '@/components/list-item/list-item'
import imgItem from '@/components/img-item/img-item'
import listBox from '@/components/list-box/list-box'
import flags from '@/components/flags/flags'
import modal from '@/components/modal/modal'
import VueScrollTo from 'vue-scrollto'
import schema from './data/article.json'
import aiChatBot from '@/components/ai-chat-bot/ai-chat-bot.vue'

export default {
  name: 'what-is-vpn',
  components: {
    'list-item': listItem,
    'img-item': imgItem,
    'list-box': listBox,
    'country-flags': flags,
    modal,
    'ai-chat-bot': aiChatBot
  },
  data () {
    return {
      pageLoaded: false,
      year: new Date().getFullYear(),
      isAiBlockedCountry: false
    }
  },
  mounted: async function () {
    // this only works because the page is being prerendered.
    // You can't actually add javascript tags like this typically
    if (window.location.host !== 'www.tunnelbear.com') {
      document.getElementById('schema-page').innerHTML = JSON.stringify(schema)
    }
    this.$nextTick(function () {
      this.pageLoaded = true
    })
    this.isAiBlockedCountry = await this.$store.state.locationService.isAiBlockedCountry()
  },
  computed: {
    modalOpen: function () {
      return this.$store.getters.modalOpen
    }
  },
  methods: {
    openModal: function () {
      this.$refs.modal.openModal()
      setTimeout(() => {
        this.$refs.logout.focus()
      }, 50)
    },
    scrollToHash: function (hash) {
      const element = hash || this.$route.hash
      if (element) {
        const options = {
          offset: -15,
          x: false,
          y: true
        }
        VueScrollTo.scrollTo(element, 1, options)
      }
    }
  }
}
</script>

<style scoped lang="stylus" src="./what-is-vpn.styl" >

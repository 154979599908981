<template>
  <nav-links-dropdown @dropdownClicked="$emit('dropdownClicked')">
    <template v-slot:link>
      <router-link :class="{'current': current}"
        :to="{name: 'what-is-vpn'}"
      >What&nbsp;is&nbsp;a&nbsp;VPN?</router-link>
    </template>
    <template v-slot:content>
      <ul>
        <li>
         <router-link  :to="{name: 'what-is-vpn'}">Why VPN? </router-link>
        </li>
        <li>
          <router-link  :to="{name: 'features'}">Features</router-link>
        </li>
        <li>
          <a href="https://www.tunnelbear.com/blog/always-use-a-vpn/">
            Online Privacy
          </a>
        </li>
        <li>
          <a href="https://www.tunnelbear.com/blog/staying-safe-in-your-cave-how-to-work-from-home-with-tunnelbear/">
            VPN for WFH
          </a>
        </li>
      </ul>
    </template>
  </nav-links-dropdown>
</template>

<script>
import navLinksDropdown from '../nav-links-dropdown/nav-links-dropdown'

export default {
  name: 'nav-link-vpn',
  props: {
    current: {
      type: Boolean
    }
  },
  components: {
    'nav-links-dropdown': navLinksDropdown
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style lang="stylus" src="../../nav.styl">

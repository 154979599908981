<template>
  <div>
    <announce>
      <p v-if="submitError" class="alert alert-danger" data-cy="error--submit">{{submitError}}</p>
    </announce>
    <form name="singupForm" id="signup-form" class="form-area auth" v-on:submit.prevent="submit" novalidate>
      <div class="form-row">
        <announce>
          <p class="field-error" :class="{ 'show-error' : errors.current === 'email' }" data-cy="error--email">Grr... That is not a valid email</p>
        </announce>
        <dynamic-input
          name="email"
          type="email"
          @model="emailTyping"
          @focus="emailFocus"
          required>Email</dynamic-input>
      </div>
      <div class="form-row">
        <announce>
          <p class="field-error" :class="{ 'show-error' : errors.current === 'password' }" data-cy="error--password">Grr... Your password must be at least 8 characters long</p>
        </announce>
        <dynamic-input
          name="password"
          type="password"
          @model="formData.password = $event"
          @blur="passwordBlur"
          @focus="passwordFocus"
          required>Password</dynamic-input>
      </div>
      <div class="form-row" v-if="showAgeCheckbox">
        <announce>
          <p class="field-error" :class="{ 'show-error' : errors.current === 'ageCheck' }" data-cy="error--age">Please confirm your age</p>
        </announce>
        <div class="age-consent-wrapper">
          <label for="age_consent" class="checkbox">
            <input type="checkbox" id="age_consent" name="age" @model="formData.ageCheck = $event" @change="onAgeCheckboxChange($event)">
            <span class="check"></span>
            I am 14 years of age or older
          </label>
        </div>
      </div>
      <submit-button :finished="finished" :submitting="submitting" @submit="submit">Create Account</submit-button>
    </form>
  </div>
</template>

<script>
import dynamicInput from '../dynamic-input/dynamic-input'
import marketing from '../../services/marketing.js'
import validator from '../../mixins/validator.js'
import Cookies from 'js-cookie'
import submitButton from '@/components/submit-button/submit-button'
import is from 'is_js'
import { eventBus } from '@/services/bus.js'

export default {
  name: 'signup-form',
  props: ['details'],
  components: {
    'dynamic-input': dynamicInput,
    'submit-button': submitButton
  },
  mixins: [validator],
  data () {
    return {
      is,
      formData: {
        email: '',
        password: '',
        ageCheck: this.isRequireAgeCheckCountry
      },
      submitting: false,
      submitted: false,
      finished: false,
      submitError: '',
      isRequireAgeCheckCountry: false
    }
  },
  mounted: async function () {
    this.isRequireAgeCheckCountry = await this.$store.state.locationService.isRequireAgeCheckCountry()
  },
  computed: {
    showAgeCheckbox: function () {
      return this.isRequireAgeCheckCountry
    },
    validations: function () {
      return {
        'email': [this.formData.email, this.is.email(this.formData.email)],
        'password': [this.formData.password, this.formData.password.length > 7],
        'ageCheck': this.isRequireAgeCheckCountry ? [this.formData.ageCheck, this.isRequireAgeCheckCountry] : ''
      }
    },
    existingUser: function () {
      return (Cookies.get('tb_user') && this.ENV.name === 'production')
    }
  },
  methods: {
    emailFocus: function () {
      eventBus.$emit('emailInputFocus')
    },
    passwordFocus: function () {
      eventBus.$emit('passwordInputFocus')
    },
    passwordBlur: function () {
      eventBus.$emit('passwordInputBlur')
    },
    emailTyping: function (val) {
      this.formData.email = val
      eventBus.$emit('emailInputTyping', this.formData.email.length)
    },
    onAgeCheckboxChange: function (eve) {
      this.formData.ageCheck = eve.target.checked
    },
    makeRequest: function () {
      if (this.submitting) return
      this.submitting = true
      this.submitError = ''
      let data = {
        email: this.formData.email,
        password: this.formData.password,
        json: '1',
        v: this.$route.query.v || 'web-1.0',
        referralKey: this.$route.query.referral || ''
      }
      if (this.details && this.details.signupType === 'NO_CONFIRM') {
        data.noconfirm = true
      }
      data = Object.assign(data, marketing.marketingParams())

      this.validateEmail(data.email).then((response) => {
        this.$store.dispatch('createAccount', data).then((response) => {
          this.submitting = false
          this.finished = true
        }).catch((reason) => {
          this.submitting = false
          this.submitError = reason || 'There was an error creating your account. Please try again.'
        })
      }).catch((reason) => {
        this.submitting = false
        this.submitError = reason || 'There was an error creating your account. Please try again.'
      })
    },
    validateEmail: function (userEmail) {
      return new Promise((resolve, reject) => {
        this.$http.post(this.ENV.coreBaseUrl + '/validateEmail', { email: userEmail }).then((response) => {
          if (response.data.is_valid) {
            resolve()
          } else {
            reject('Hmm... this doesn\'t look like a valid email.')
          }
        }).catch((error) => {
          if (error.response && (error.response.status > 499 && error.response.status < 600)) {
            // if mailgun is down, allow resolution
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    submit: function () {
      this.submitted = true
      if (this.errors.all.length === 0) {
        if (this.existingUser) {
          this.submitError = 'We think you may already have a TunnelBear account. Please login with this account.'
          return
        }
        this.makeRequest()
      }
    }
  }
}
</script>

<style scoped lang="stylus" src="./signup-form.styl" >

const salesStart = new Date('Sep 16, 2024')
const salesEnd = new Date('Oct 31, 2024')

const now = new Date()
const isLive = salesStart <= now && now < salesEnd // ensure lower bound is inclusive (<=) to avoid test date gotcha

export default {
  isLive: isLive,
  startDate: salesStart,
  endDate: salesEnd,
  name: 'fall-sale',
  products: ['YEARLY_PAYMENT_RECURRING'],
  title: 'Fall Sale! Get 67% off',
  titleButton: '67% off',
  tagline: 'Smell that pumpkin spice in the air and save big this Fall on annual TunnelBear subscriptions!',
  salePrices: {
    'YEARLY_PAYMENT_RECURRING': '39.99',
    'THREE_YEAR_PAYMENT': '99.99',
    'TWO_YEAR_PAYMENT': '65.99' // gets replaced with backend values in the store if there's a backend sale
  },
  countdown: {
    show: false,
    endDate: salesEnd
  },
  couponSale: isLive,
  couponProducts: {
    // 'SEASONAL2021': 'YEARLY_PAYMENT_RECURRING'
    // 'GRIZZLYSALE2020': 'YEARLY_PAYMENT_RECURRING',
    // 'POLARSALE2020': 'THREE_YEAR_PAYMENT',
    // 'KODIAKSALE2020': 'TWO_YEAR_PAYMENT',
  },
  couponRoute: {
    name: 'checkout',
    query: {
      couponCode: 'FALLSALE2024',
      ref_id: 'website_fall_sale',
      utm_source: 'website',
      utm_medium: 'direct',
      utm_campaign: 'fall_2024'
    }
  },
  bannerRoute: {
    name: 'checkout',
    query: {
      couponCode: 'FALLSALE2024',
      utm_source: 'salebanner',
      utm_medium: 'direct',
      utm_campaign: 'fall_2024'
    }
  }
}

<template>
  <div class="input-wrap">
    <input v-if="(type !== 'select')"
      :name="name"
      :id="name"
      :type="type"
      @focus="focus()"
      @blur="blur()"
      :required="required"
      :data-cy="name + '-input'"
      v-model.trim="model"
      :autocomplete="autocomplete">
    <div v-else class="select-wrap">
      <select
        :name="name"
        :id="name"
        :type="type"
        @focus="focused = true"
        :required="required"
        v-model="model"
        :data-cy="name + '-select'"
        :class="{ 'selected': model } ">
        <slot name="options"></slot>
      </select>
      <span class="caret"></span>
    </div>
    <label :for="name" :class="{ 'active': active }">
      <slot></slot>
    </label>
  </div>
</template>

<script>

export default {
  props: {
    'name': String,
    'type': String,
    'required': Boolean,
    'placeholder': String,
    'autocomplete': String,
    'reset': Boolean
  },
  data () {
    return {
      focused: false,
      model: this.placeholder || ''
    }
  },
  computed: {
    active: function () {
      return this.focused || !!this.model
    }
  },
  methods: {
    focus: function () {
      this.focused = true
      if (this.type === 'password' || this.type === 'email') {
        this.$emit('focus')
      }
    },
    blur: function () {
      if (this.type === 'password' || this.type === 'email') {
        this.$emit('blur')
      }
    }
  },
  watch: {
    model (value) {
      this.$emit('model', value)
    },
    reset () {
      this.model = ''
    }
  }
}
</script>

<style scoped lang="stylus" src="./dynamic-input.styl" >

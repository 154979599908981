export default [
  {
    code: 'uk',
    country: 'UK',
    spritePosition: 0,
    sortAs: 'United Kingdom'
  },
  {
    code: 'us',
    country: 'USA',
    spritePosition: 1,
    sortAs: 'United States'
  },
  {
    code: 'ca',
    country: 'Canada',
    spritePosition: 2
  },
  {
    code: 'de',
    country: 'Germany',
    spritePosition: 3
  },
  {
    code: 'jp',
    country: 'Japan',
    spritePosition: 4
  },
  {
    code: 'ie',
    country: 'Ireland',
    spritePosition: 5
  },
  {
    code: 'es',
    country: 'Spain',
    spritePosition: 6
  },
  {
    code: 'fr',
    country: 'France',
    spritePosition: 7
  },
  {
    code: 'it',
    country: 'Italy',
    spritePosition: 8
  },
  {
    code: 'nl',
    country: 'Netherlands',
    spritePosition: 9
  },
  {
    code: 'se',
    country: 'Sweden',
    spritePosition: 10
  },
  {
    code: 'ch',
    country: 'Switzerland',
    spritePosition: 11
  },
  {
    code: 'at',
    country: 'Australia',
    spritePosition: 12
  },
  {
    code: 'sg',
    country: 'Singapore',
    spritePosition: 13
  },
  {
    code: 'br',
    country: 'Brazil',
    spritePosition: 14
  },
  {
    code: 'dk',
    country: 'Denmark',
    spritePosition: 15
  },
  {
    code: 'no',
    country: 'Norway',
    spritePosition: 16
  },
  {
    code: 'mx',
    country: 'Mexico',
    spritePosition: 17
  },
  {
    code: 'nz',
    country: 'New Zealand',
    spritePosition: 19
  },
  {
    code: 'ro',
    country: 'Romania',
    spritePosition: 20
  },
  {
    code: 'fi',
    country: 'Finland',
    spritePosition: 21
  },
  {
    code: 'ar',
    country: 'Argentina',
    spritePosition: 22
  },
  {
    code: 'pe',
    country: 'Peru',
    spritePosition: 23
  },
  {
    code: 'co',
    country: 'Colombia',
    spritePosition: 24
  },
  {
    code: 'cl',
    country: 'Chile',
    spritePosition: 25
  },
  {
    code: 'kr',
    country: 'South Korea',
    spritePosition: 26
  },
  {
    code: 'md',
    country: 'Moldova',
    spritePosition: 27
  },
  {
    code: 'cy',
    country: 'Cyprus',
    spritePosition: 28
  },
  {
    code: 'pt',
    country: 'Portugal',
    spritePosition: 29
  },
  {
    code: 'si',
    country: 'Slovenia',
    spritePosition: 31
  },
  {
    code: 'lt',
    country: 'Lithuania',
    spritePosition: 32
  },
  {
    code: 'gr',
    country: 'Greece',
    spritePosition: 33
  },
  {
    code: 'rs',
    country: 'Serbia',
    spritePosition: 34
  },
  {
    code: 'hu',
    country: 'Hungary',
    spritePosition: 35
  },
  {
    code: 'pl',
    country: 'Poland',
    spritePosition: 36
  },
  {
    code: 'cz',
    country: 'Czech Republic',
    spritePosition: 37
  },
  {
    code: 'at',
    country: 'Austria',
    spritePosition: 38
  },
  {
    code: 'bg',
    country: 'Bulgaria',
    spritePosition: 39
  },
  {
    code: 'be',
    country: 'Belgium',
    spritePosition: 40
  },
  {
    code: 'ke',
    country: 'Kenya',
    spritePosition: 41
  },
  {
    code: 'ng',
    country: 'Nigeria',
    spritePosition: 42
  },
  {
    code: 'za',
    country: 'South Africa',
    spritePosition: 43
  },
  {
    code: 'id',
    country: 'Indonesia',
    spritePosition: 44
  },
  {
    code: 'ph',
    country: 'Philippines',
    spritePosition: 45
  },
  {
    code: 'tw',
    country: 'Taiwan',
    spritePosition: 46
  },
  {
    code: 'my',
    country: 'Malaysia',
    spritePosition: 47
  },
  {
    code: 'lv',
    country: 'Latvia',
    spritePosition: 48
  }
]

<template>
  <div>
    <div class="ai-chat-bot-container">
      <div class="message-container">
        <div class="message-header">
          <div class="logo-container">
            <img class="logo"  src="./images/title-logo.svg" alt="logo">
          </div>
          <div class="minimize-container">
            <button class="minimize-btn"><img class="minimize-img" src='@/../static/images/minimize.svg' alt='collapse'></button>
          </div>
        </div>
        <div class="message-content">
          <div class="messages m-bottom-10">
            <div class="messages-list">
              <div class="msg-ai-container">
                <div class="welcome-msg-container mtop-50">
                  <span class="avtr">
                    <img class="welcome-msg-avtr" src="./images/chat-icon.svg" alt="">
                  </span>
                  <div class="msg-ai welcome-msg">
                    <p class="hello-txt"><b>HELLO!</b></p>
                    <p class="info-txt"><b>I’m RoboCub, your very own personal AI assistant. I’m not as smart as a real bear, but will do my best to help.</b></p>
                  </div>
                </div>
                <div class="msg-ai welcome-sub-info">
                  <div class='chat-bubble mtop-5'>
                    <p>Do you have any questions about TunnelBear?</p>
                  </div>
                  <div class='res-txt-bot mtop-5 pbottom-10'>
                    <p>If you aren’t sure, here are some common topics I typically see:
                      <ul class="mleft-15">
                        <li>Account issues</li>
                        <li>Technical questions</li>
                        <li>General questions</li>
                        <li>Feedback</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form id='msg_form' class="mtop-10">
            <div class="input-container input-blank">
              <input id="msg_input" type="text" placeholder="Send a message...">
              <button class="send-msg" type="submit"><img src="./images/submit-chat-btn.svg" alt="submit"></button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="chat-icon-btn"><img  src="./images/ai-agent-bear.svg" alt="chat-icon"></button>
  </div>
</template>

<script>
export default {
  name: 'ai-chat-bot',
  created: function () {
    const head = document.querySelector('head')
    let chatScriptUrl = this.ENV.aiChatBotScriptURL
    const script = document.querySelector('#aiChatBot')
    if (script != null) {
      head.removeChild(script)
    }
    chatScriptUrl = process.IS_TEST ? '' : chatScriptUrl // not load chatbot script while running test cases
    const aiChatBotScript = document.createElement('script')
    aiChatBotScript.id = 'aiChatBot'
    aiChatBotScript.setAttribute('src', chatScriptUrl)
    head.appendChild(aiChatBotScript)
  }
}
</script>

<style lang="stylus" src="./ai-chat-bot.styl" scoped>

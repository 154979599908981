import is from 'is_js'

export default {
  data () {
    return {
      widgetAdded: !!document.getElementById('ze-snippet')
    }
  },
  methods: {
    displayWidget: function () {
      if (this.widgetAdded) {
        try {
          const zendeskWidget = document.getElementById('webWidget')
          const zendeskLauncher = document.getElementById('launcher')
          zendeskWidget.style.display = 'block'
          zendeskLauncher.style.display = 'block'
        } catch (err) {

        }
      } else {
        try {
          const zendeskScript = document.createElement('script')
          zendeskScript.setAttribute('id', 'ze-snippet')
          zendeskScript.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=7d23deec-71d7-4a65-8c33-b8c6d068194b')
          document.body.appendChild(zendeskScript)
          this.widgetAdded = true
        } catch (err) {
        }
      }
    },
    hideWidget: function () {
      try {
        const zendeskWidget = document.getElementById('webWidget')
        const zendeskLauncher = document.getElementById('launcher')
        zendeskWidget.style.display = 'none'
        zendeskLauncher.style.display = 'none'
      } catch (err) {
      }
    }
  },
  mounted: function () {
    if (is.not.mobile()) {
      this.displayWidget()
    }
  },
  beforeDestroy: function () {
    if (this.widgetAdded) {
      this.hideWidget()
    }
  }
}

<template>
  <nav id="main-header">
    <div class="nav-inner complex">
      <div class="top-bar">
        <router-link :to="{ name: 'teams'}" class="logo teams">
          <img src="../../images/tb-teams.svg" alt="TunnelBear for Teams">
        </router-link>
      </div>
      <div class="drawer">
        <div id="nav-items">
          <div class="nav-menu">
            <div class="nav-items-wrap">
              <div class="nav-item">
                <button @click="scrollPricingIntoView()" class="plain">Plans</button>
              </div>
              <nav-link-apps
                :current="current"
                @dropdownClicked="toggleMobileMenu()"
              ></nav-link-apps>
              <div class="nav-item">
                <a v-if="isTeamUser" href="/team/account/overview">My&nbsp;Account</a>
                <router-link v-else :to="{ name: 'overview' }">My&nbsp;Account</router-link>
              </div>
              <div class="nav-item">
                <router-link :to="{ name: 'download' }">Download</router-link>
              </div>
            </div>
            <div class="nav-item bottom">
              <a href="/team/create" class="btn hidden-xs">Try&nbsp;it&nbsp;free<span class="hidden-xs">&nbsp;for&nbsp;7&nbsp;days</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import navLinkApps from '../nav-link-apps/nav-link-apps'
import navLinkVpn from '../nav-link-vpn/nav-link-vpn'

export default {
  name: 'nav-teams',
  components: {
    'nav-link-apps': navLinkApps,
    'nav-link-vpn': navLinkVpn
  },
  data () {
    return {}
  },
  computed: {
    isTeamUser: function () {
      return !!this.$store.getters.tb4t
    }
  },
  methods: {
    scrollPricingIntoView: function () {
      this.$nextTick(() => {
        const el = document.getElementById('billing')
        if (el) {
          const position = el.getBoundingClientRect()
          window.scrollBy({
            top: (position.y - 40),
            behavior: 'smooth'
          })
        }
      })
    }
  }
}
</script>

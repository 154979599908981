<template>
  <div class="view-wrap">
    <div class="view-content">
      <section id="platforms" class="">
        <div class="platforms bg-alt">
          <router-link v-for="app in apps" :to="{'path' : '/apps/' + app.toLowerCase() + '/'}" :key="app.id" class="platform-wrap">
            <div :class="'platform ' + app.toLowerCase()"></div>
            <p class="hidden-xs">{{app}}</p>
          </router-link>
        </div>
      </section>
      <div id="apps" class="bg-regular">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'apps',
  data () {
    return {
      apps: ['iOS', 'Android', 'Mac', 'Windows', 'Browser', 'Blocker']
    }
  }
}
</script>

<style scoped lang="stylus" src="./apps.styl" >

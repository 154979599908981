<template>
  <div class="nav-menu">
    <div class="nav-items-wrap">
      <nav-link-vpn
        :current="current === 'what-is-vpn'"
       @click="$emit('closeNav')"
      ></nav-link-vpn>
      <div class="nav-item">
        <a href="https://help.tunnelbear.com/hc/en-us">Help</a>
      </div>
      <div class="nav-item" :class="{'current': current === 'overview'}" @click="$emit('closeNav')">
        <router-link :to="{ name: 'overview' }">My&nbsp;Account</router-link>
      </div>
      <div class="nav-item visible-xs">
        <button class="plain" @click="$emit('logout')">Logout</button>
      </div>
    </div>
    <div class="nav-item bottom" @click="$emit('closeNav')">
      <router-link :to="{ name: 'download' }" class="btn" :class="{'hollow': current === 'download'}">Download</router-link>
    </div>
  </div>
</template>

<script>
import navLinkVpn from '../nav-link-vpn/nav-link-vpn'

export default {
  name: 'nav-paid',
  props: ['current', 'appRoute'],
  components: {
    'nav-link-vpn': navLinkVpn
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="stylus" src="./nav-paid.styl" >

<template>
  <section id="apps" :class="sectionClass">
    <div class="section-inner content">
      <div class="img-holder" :class="is.mac() || is.ios() ? 'apple' : 'other'">
        <img v-if="imgs[0]" class="laptop" :src="imgs[0]" alt="">
      </div>
      <div class="element xs centered text-center">
        <slot></slot>
      </div>
      <div class="img-holder" :class="is.mac() || is.ios() ? 'apple' : 'other'">
        <img v-if="imgs[2]" class="mobile" :src="imgs[2]" alt="">
        <img v-if="imgs[1]" class="tablet" :src="imgs[1]" alt="">
      </div>
    </div>
  </section>
</template>
<script>
import is from 'is_js'

export default {
  name: 'home',
  props: ['sectionClass'],
  data () {
    return {
      is,
      imgs: []
    }
  },
  beforeMount: function () {
    this.getImgPaths()
  },
  methods: {
    getImgPaths: function () {
      const mobile = is.mobile() ? 'm-' : ''
      const retina = (window.devicePixelRatio > 1 || mobile) ? '@2x' : ''
      const fileNames = (is.mac() || is.ios()) ? ['mac', 'ipad', 'iphone'] : ['windows', 'android-tablet', 'android']
      for (const file of fileNames) {
        const filePath = require('./images/' + mobile + file + retina + '.png')
        this.imgs.push(filePath)
      }
    }
  }
}
</script>
<style scoped lang="stylus" src="./app-wrap.styl" >

<template>
  <section id="audit">
    <div class="section-inner">
      <div class="container">
        <div class="content element large centered">
          <div class="copy text-center">
            <img-set
              lazyload
              classes="lg-screen"
              :imgSrc="require('@/components/audit/images/audit_badge.svg')"
              alt="Independently Audited Code"
              :width="115" :height="53"/>
            <h2>Independently audited</h2>
            <p>We take security seriously. TunnelBear is the only VPN in the world to publish regular, independent&nbsp;<a :href="this.external.audit">security&nbsp;audits</a>.</p>
          </div>
          <div class="img-holder">
            <img-set
              lazyload
              classes="lg-screen"
              :imgSrc="require('@/components/audit/images/audit.svg')"
              alt="Independently Audited Code"
              :width="438" :height="473"/>
            <img-set
              lazyload
              classes="sm-screen"
              :imgSrc="require('@/components/audit/images/audit_bear_badge.svg')"
              alt="Independently Audited Code"
              :width="279" :height="301"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'audit',
  data () {
    return {}
  }
}
</script>

<style scoped lang="stylus" src="./audit.styl" >

export default {
  'MONTHLY_PAYMENT': {
    name: 'monthly',
    title: '1 Month',
    desc: 'Monthly plan',
    query: ['giant', 'monthly'],
    renews: true,
    endpointOverride: '',
    cycleMonths: 1,
    cycleYears: 0,
    includes: []
  },
  'YEARLY_PAYMENT_RECURRING': {
    name: 'yearly',
    title: '1 Year',
    desc: 'Yearly plan',
    query: ['grizzly', 'yearly'],
    renews: true,
    endpointOverride: '',
    cycleMonths: 12,
    cycleYears: 1,
    includes: []
  },
  'TWO_YEAR_PAYMENT': {
    name: 'twoYear',
    title: '2 Years',
    desc: 'Two year plan',
    query: ['kodiak', 'twoyear'],
    renews: false,
    endpointOverride: '/doOneTimePayment',
    cycleMonths: 24,
    cycleYears: 2,
    includes: []
  },
  'TUNNELBEAR_REMEMBEAR_THREE_YEAR_BUNDLE': {
    name: 'bundle',
    title: '3 Years',
    desc: 'Three year plan',
    query: ['bundle'],
    renews: false,
    endpointOverride: '/doOneTimePayment',
    cycleMonths: 36,
    cycleYears: 3,
    includes: ['remembear']
  },
  'THREE_YEAR_PAYMENT': {
    name: 'threeyear',
    title: '3 Years',
    desc: 'Three year plan',
    query: ['threeyear'],
    renews: false,
    endpointOverride: '/doOneTimePayment',
    cycleMonths: 36,
    cycleYears: 3,
    includes: []
  },
  'YEARLY_PAYMENT_RECURRING_TRIAL': {
    name: 'trial',
    title: '1 Year',
    desc: '7-Day Trial',
    query: [],
    renews: true,
    endpointOverride: '',
    cycleMonths: 12,
    cycleYears: 1,
    includes: []
  },
  'GIFT_OF_BEAR': {
    name: 'gotb',
    title: '1 Year',
    desc: 'Gift of the Bear',
    query: [],
    renews: false,
    endpointOverride: '',
    cycleMonths: 12,
    cycleYears: 1,
    includes: []
  },
  'TB4T_YEARLY_PAYMENT': {
    name: 'tb4t',
    title: '1 Year',
    desc: 'TunnelBear for Teams',
    query: [],
    renews: true,
    endpointOverride: '',
    cycleMonths: 12,
    cycleYears: 1,
    includes: []
  },
  'TUNNELBEAR_BY_MCAFEE_MONTHLY_739': {
    name: 'tbMcafeeMonthly_739',
    title: '1 Month',
    desc: 'Monthly plan',
    query: [],
    utmSource: '739',
    renews: true,
    endpointOverride: '',
    cycleMonths: 1,
    cycleYears: 0,
    includes: []
  },
  'TUNNELBEAR_BY_MCAFEE_YEARLY_739': {
    name: 'tbMcafeeYearly',
    title: '1 Year',
    desc: 'Yearly plan',
    query: [],
    utmSource: '739',
    renews: true,
    endpointOverride: '',
    cycleMonths: 12,
    cycleYears: 1,
    includes: []
  },
  'TUNNELBEAR_BY_MCAFEE_MONTHLY_1641': {
    name: 'tbMcafeeMonthly_1641',
    title: '1 Month',
    desc: 'Monthly plan',
    query: [],
    utmSource: '1641',
    renews: true,
    endpointOverride: '',
    cycleMonths: 1,
    cycleYears: 0,
    includes: []
  },
  'TUNNELBEAR_BY_MCAFEE_YEARLY_1641': {
    name: 'tbMcafeeYearly_1641',
    title: '1 Year',
    desc: 'Yearly plan',
    query: [],
    utmSource: '1641',
    renews: true,
    endpointOverride: '',
    cycleMonths: 12,
    cycleYears: 1,
    includes: []
  }
}

<template>
  <nav-links-dropdown @dropdownClicked="$emit('dropdownClicked')">
    <template v-slot:link>
      <router-link :class="{'current': isAppsRoute }"
        :to="{name: deviceAppRoute()}"
      >Apps</router-link>
    </template>
    <template v-slot:content>
      <ul>
        <li :class="{'current': current === 'mac' }">
          <div class="device mac"></div>
          <router-link :to="{name: 'mac'}">Mac</router-link>
        </li>
        <li :class="{'current': current === 'windows' }">
          <div class="device windows"></div>
          <router-link :to="{name: 'windows'}">Windows</router-link>
        </li>
        <li :class="{'current': current === 'ios' }">
          <div class="device ios"></div>
          <router-link :to="{name: 'ios'}">iPhone &amp; iPad</router-link>
        </li>
        <li :class="{'current': current === 'android' }">
          <div class="device android"></div>
          <router-link :to="{name: 'android'}">Android</router-link>
        </li>
        <li :class="{'current': current === 'browser' }">
          <div class="device browser"></div>
          <router-link :to="{name: 'browser'}">Browser Extensions</router-link>
        </li>
        <li :class="{'current': current === 'blocker' }">
          <div class="device blocker"></div>
          <router-link :to="{name: 'blocker'}">Blocker</router-link>
        </li>
      </ul>
    </template>
  </nav-links-dropdown>
</template>

<script>
import is from 'is_js'
import navLinksDropdown from '../nav-links-dropdown/nav-links-dropdown'

export default {
  name: 'nav-link-apps',
  props: {
    current: {
      type: String
    }
  },
  components: {
    'nav-links-dropdown': navLinksDropdown
  },
  data () {
    return {}
  },
  computed: {
    isAppsRoute: function () {
      const apps = ['windows', 'android', 'mac', 'ios', 'browser', 'blocker']
      return apps.indexOf(this.current) > -1
    }
  },
  methods: {
    deviceAppRoute: function () {
      let app = 'mac'
      if (is.mobile() || is.tablet()) {
        app = is.android() ? 'android' : 'ios'
      } else {
        app = is.windows() ? 'windows' : 'mac'
      }
      return app
    }
  }
}
</script>

<style lang="stylus" src="./nav-link-apps.styl">
<style lang="stylus" src="../../nav.styl">

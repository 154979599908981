import { render, staticRenderFns } from "./wirecutter.vue?vue&type=template&id=657d533b&scoped=true"
import script from "./wirecutter.vue?vue&type=script&lang=js"
export * from "./wirecutter.vue?vue&type=script&lang=js"
import style0 from "./wirecutter.styl?vue&type=style&index=0&id=657d533b&prod&scoped=true&lang=stylus&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657d533b",
  null
  
)

export default component.exports
<template>
  <div class="view-wrap">
    <modal ref="modal" labelID="portal-desc">
      <portal :type="modalType" labelID="portal-desc"></portal>
    </modal>
    <div class="view-content" :inert="modalOpen">
      <template v-if="is.mobile() || is.tablet()">
        <section id="mobile-hero" class="bg-light">
          <div class="section-inner">
            <div class="container text-center">
              <h1 class="h2">Download TunnelBear</h1>
              <img v-if="is.ios()" class="mobile" src="./images/iphone@2x.png" alt="">
              <img v-else class="mobile" src="./images/android@2x.png" alt="">
            </div>
          </div>
        </section>
        <section id="mobile-content">
          <div class="section-inner">
            <div class="container text-center">
              <div v-if="is.ios()" class="store">
                <a :href="external.downloadLinks.ios">
                  <img class="app-store" src="~@/images/app-store.svg" alt="Download on the App Store">
                </a>
                <p class="fine-print">iPads and iPhones with {{this.apps.ios.osSupport}}</p>
              </div>
              <div v-else class="store">
                <a :href="external.downloadLinks.android">
                  <img class="google-play" src="~@/images/google-play.svg" alt="Get it on Google Play">
                </a>
                <p class="fine-print">Android  {{this.apps.android.osSupport}}</p>
              </div>
              <p>Psst... You can get a TunnelBear for <router-link :to="{name: 'download-devices'}">all your devices</router-link>!</p>
              <img class="devices" src="./images/home_device_icons2@2x.png" alt="Available for Android, iOS, Apple, Windows, Chrome browser.">
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <section id="hero" class="bg-light">
          <div class="section-inner">
            <div class="container vertical-center">
              <div class="row">
                <div class="col-sm-12 col-md-7 vertical-center text-center">
                  <div class="copy" data-cy="copy--downloading">
                    <template v-if="autodownload">
                      <h1>Downloading TunnelBear&nbsp;for&nbsp;{{deviceName}}</h1>
                      <p>Your TunnelBear download should start automatically.</p>
                      <p>If it doesn't, <button @click="download()" class="btn plain link">restart the download</button>.</p>
                    </template>
                    <template v-else>
                      <h1>Download TunnelBear</h1>
                      <button data-cy="btn--download" v-if="!autodownload" @click="download()" class="btn btn-lg submit-btn">Download for {{deviceName}}</button>
                    </template>
                    <p class="fine-print os-version">{{this.supportedMinOSVersion}}</p>
                    <p class="different-device" data-cy="copy--different-device" :class="{'fine-print-lg': autodownload}">Or get TunnelBear for a <router-link :to="{name: 'download-devices'}">different&nbsp;device</router-link>.</p>
                  </div>
                </div>
                <div class="col-sm-12 col-md-5 img">
                  <img v-if="autodownload || triggeredDownload" class="dl-bear" srcset="./images/download_bear.gif 1x, ./images/download_bear@2x.gif 2x" src="./images/download_bear@2x.gif" alt="">
                  <img v-else class="dl-bear" srcset="./images/download_bear_pause.png 1x, ./images/download_bear_pause@2x.png 2x" src="./images/download_bear_pause@2x.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="instructions">
          <div class="section-inner">
            <div class="container text-center">
              <div class="steps">
                <div v-if="is.mac()" class="step">
                  <img srcset="./images/download-open.png 1x, ./images/download-open@2x.png 2x" src="./images/download-open@2x.png" alt="TunnelBear zip file">
                  <span class="circle-num">1</span>
                  <p class="h6">Open the file</p>
                  <p>Double-click the TunnelBear Zip&nbsp;file you downloaded</p>
                </div>
                <div class="step">
                  <img srcset="./images/download-click.png 1x, ./images/download-click@2x.png 2x" src="./images/download-click@2x.png" alt="TunnelBear Application Icon">
                  <template v-if="is.mac()">
                    <span class="circle-num">2</span>
                    <p class="h6">Launch the app</p>
                    <p>Double-click the TunnelBear icon and select "Move to Applications&nbsp;Folder"</p>
                  </template>
                  <template v-else>
                    <span class="circle-num">1</span>
                    <p class="h6">Install the app</p>
                    <p>Double-click the new TunnelBear icon and follow the on-screen instructions</p>
                  </template>
                </div>
                <div class="step">
                  <img srcset="./images/download-login.png 1x, ./images/download-login@2x.png 2x" src="./images/download-login@2x.png" alt="Email input, Password input, Log in button">
                  <span v-if="is.mac()" class="circle-num">3</span>
                  <span v-else class="circle-num">2</span>
                  <p class="h6">Sign&nbsp;up or&nbsp;log&nbsp;in</p>
                  <p>Follow the on-screen instructions to log in or create an&nbsp;account</p>
                </div>
                <div class="step">
                  <img srcset="./images/download-toggle.png 1x, ./images/download-toggle@2x.png 2x" src="./images/download-toggle@2x.png" alt="Toggle switch">
                  <span v-if="is.mac()" class="circle-num">4</span>
                  <span v-else class="circle-num">3</span>
                  <p class="h6">Start tunneling</p>
                  <p>Pick a country or select Fastest for the fastest speeds, then toggle the switch&nbsp;ON!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <section id="subnotes">
        <div class="section-inner">
          <div class="container">
            <div class="notices">
              <router-link :to="{ name: 'legal-notices'}" class="fine-print">Notice and Attribution</router-link>
              <a href="https://help.tunnelbear.com/hc/en-us/articles/360007120951" class="fine-print">Uninstall TunnelBear</a>
            </div>
            <p class="fine-print terms-policy-text">
                By downloading <strong>TunnelBear</strong>,
                you agree to the <router-link :to="{name: 'terms-of-service'}" target="_blank">Terms&nbsp;of&nbsp;Service</router-link>
                and <router-link :to="{name: 'privacy-policy'}" target="_blank">Privacy&nbsp;Policy</router-link>.</p>
          </div>
        </div>
      </section>
      <trigger-download ref="download"></trigger-download>
    </div>
  </div>
</template>

<script>
import modal from '@/components/modal/modal'
import portal from '@/components/portal/portal'
import Cookies from 'js-cookie'
import is from 'is_js'
import triggerDownload from '@/components/trigger-download/trigger-download'

export default {
  name: 'download',
  components: {
    modal,
    portal,
    'trigger-download': triggerDownload
  },
  data () {
    return {
      is,
      modalType: 'signup',
      watcher: null,
      triggeredDownload: false,
      downloaded: Cookies.get('downloaded')
    }
  },
  computed: {
    checkAuth: function () {
      return this.$route.path === '/account/download'
    },
    deviceName: function () {
      let deviceText = 'Windows'
      if (this.is.mac()) deviceText = 'Mac'
      return deviceText
    },
    supportedMinOSVersion: function () {
      if (this.is.mac()) {
        return '64-bit macOS ' + this.apps.mac.osSupport
      } else {
        return 'Windows ' + this.apps.windows.osSupport
      }
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    autodownload: function () {
      return (this.ENV.name === 'production' || window.Cypress) &&
      !Cookies.get('downloaded') &&
      !this.is.mobile() &&
      !this.is.touchDevice()
    },
    modalOpen: function () {
      return this.$store.getters.modalOpen
    }
  },
  created: function () {
    this.checkExistingAccount()
    this.watcher = this.$store.watch((state, getters) => getters.isLoggedIn, () => {
      this.$refs.modal.closeModal()
      setTimeout(() => {
        this.download()
      }, 2000)
    })
    this.$store.state.locationService.getCountryCode().then(country => {
      if (country === 'IN') {
        this.$router.replace({ name: 'pricing' })
      }
    })
  },
  mounted () {
    if (this.checkAuth && !this.isLoggedIn) {
      // move to download page after 2fa
      this.$store.dispatch('saveNextRoute', { name: 'download' })
      this.$refs.modal.openModal()
      return
    }
    this.$nextTick(function () {
      setTimeout(() => {
        if (this.autodownload) {
          this.download()
        }
      }, 2000)
    })
  },
  methods: {
    checkExistingAccount: function () {
      if (Cookies.get('tb_user') && (this.ENV.name === 'production' || window.Cypress)) {
        this.modalType = 'login'
      }
    },
    download: function () {
      if (!this.autodownload) this.triggeredDownload = true
      this.$refs.download.triggerDownload()
    }
  },
  beforeDestroy: function () {
    this.watcher()
  }
}
</script>

<style scoped lang="stylus" src="./download.styl" >

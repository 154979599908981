<template>
  <div class="view-wrap">
    <div class="view-content">
      <div class="content-horizontal">
        <div class="horizontal-inner">
          <section id="top-ribbon">
            <div class="section-inner">
              <div class = "floating-title">
                <h1>Choose your plan</h1>
              </div>
              <div class="mobile-menu">
                <button @click="mobileSelect = 'free'" :class="{'selected': mobileSelect === 'free'}">Free</button>
                <button @click="mobileSelect = 'yearly'" :class="{'selected': mobileSelect === 'yearly'}">1&nbsp;Year</button>
                <button @click="mobileSelect = 'monthly'" :class="{'selected': mobileSelect === 'monthly'}">1&nbsp;Month</button>
              </div>
            </div>
          </section>

          <section id="bear-images">
            <div class="section-inner">
              <div class="container">
                <div class="bears" :class="'select-' + mobileSelect">
                  <div class="bear-pic item-free">
                    <img class="free hidden-xs" src="./images/bear-free.svg" alt="">
                    <img class="free visible-xs" src="./images/mobile-free.svg" alt="">
                  </div>
                  <div class="bear-pic item-monthly">
                    <img class="monthly hidden-xs" src="./images/bear-monthly.svg" alt="">
                    <img class="monthly visible-xs" src="./images/mobile-monthly.svg" alt="">
                  </div>
                  <div class="bear-pic item-yearly">
                    <img class="yearly" srcset="./images/bear-yearly.png 1x, ./images/bear-yearly@2x.png 2x" src="./images/bear-yearly@2x.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="bear-plans">
            <div class="section-inner">
              <div class="container">
                <div class="row" :class="'select-' + mobileSelect">
                  <div class="col-12 col-md-4 item-free item-column">
                    <div class="content">
                      <h2 class="h2">Free</h2>
                      <ul class="check-list yellow copy">
                        <li>2GB of data a month</li>
                      </ul>
                      <p class="copy">Find out if TunnelBear works for you. Upgrade at any time for unlimited data.</p>
                      <router-link aria-label="Download TunnelBear for Free with a data limit of 2GB per month" class="btn btn-lg" :to="{name: 'download'}">Download</router-link>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 item-monthly item-column">
                    <div class="content">
                      <h2 class="h2">1 Month</h2>
                      <ul class="check-list copy">
                        <li>Unlimited data</li>
                        <li>Unlimited devices</li>
                        <li>Priority customer service</li>
                      </ul>
                      <div class="pricing">
                        <p><span class="amount">${{ pricing.MONTHLY_PAYMENT.regularPrice }}</span>/month</p>
                        <p class="fine-print-lg">${{ pricing.MONTHLY_PAYMENT.regularPrice }} billed every month</p>
                      </div>
                      <router-link :aria-label="'Get Unlimited TunnelBear for $' + pricing.MONTHLY_PAYMENT.regularPrice + 'USD per month'" class="btn btn-lg" :to="{name: 'checkout', query: {plan: 'monthly'}}">Get 1 Month</router-link>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 item-yearly item-column">
                    <div class="save-circle">
                      <p class="save">Save {{ grizzlyDiscount }}%</p>
                    </div>
                    <div class="content">
                      <h2 class="h2">1 Year</h2>
                      <ul class="check-list copy">
                        <li>Unlimited data</li>
                        <li>Unlimited devices</li>
                        <li>Priority customer service</li>
                      </ul>
                      <div class="pricing">
                        <p><span class="amount accent-text">${{ (currentPrice / 12).toFixed(2) }}</span>/month</p>
                        <p class="fine-print-lg"> ${{ currentPrice }} billed every 12 months</p>
                      </div>
                      <router-link :aria-label="'Get Unlimited TunnelBear for $' + currentPrice + 'USD per year'" class="btn btn-lg" :to="{name: 'checkout', query: {plan: 'yearly', couponCode: 'BLACKFRIDAY2019'}}">Get 1 Year</router-link>
                    </div>
                  </div>
                </div>
                <div class="payment-info">
                  <img class="payment-img" src="./images/payment-options.svg" alt="Acceptable payment types include Visa, Mastercard, American Express, Jars of Honey">
                  <p class="fine-print-lg payment-note col-xs-12">All pricing shown in USD</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import is from 'is_js'

export default {
  name: 'plans',
  components: {},
  data () {
    return {
      is,
      mobileSelect: 'yearly'
    }
  },
  computed: {
    pricing: function () {
      return this.$store.state.pricingService.pricing
    },
    currentPrice: function () {
      return this.pricing.YEARLY_PAYMENT_RECURRING.salePrice || this.pricing.YEARLY_PAYMENT_RECURRING.regularPrice
    },
    giantYearly: function () {
      return this.pricing.MONTHLY_PAYMENT.regularPrice * 12
    },
    grizzlyDiscount: function () {
      const percent = Math.abs((this.giantYearly - this.currentPrice) / (this.pricing.MONTHLY_PAYMENT.regularPrice * 12))
      return Math.round(percent * 100)
    }
  },
  beforeMount: function () {
    this.$emit('modifyLayout', {
      bgColor: 'bg-regular'
    })
  },
  created: function () {
    this.$store.state.pricingService.getPricingData()
  }
}
</script>

<style scoped lang="stylus" src="./pricing-old.styl" >

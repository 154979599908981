<template>
  <div id="footer" class="view-wrap page-element bg-dark" :inert="modalOpen">
    <div class='view-content'>
      <section class="smaller" v-if="!minimal">
        <div class="section-inner">
          <div class="container">
            <div class="footer-content" v-if="!isTbMcAfeeLandingPage">
              <div class="footer-column">
                <h6>TunnelBear</h6>
                <ul>
                  <li><router-link :to="{ name: 'what-is-vpn'}">What&nbsp;is a&nbsp;VPN?</router-link></li>
                  <li><router-link :to="{ name: 'download'}">Download</router-link></li>
                  <li><router-link :to="{ name: 'features'}">Features</router-link></li>
                  <li><router-link :to="{ name: 'pricing'}">Pricing</router-link></li>
                  <li><router-link :to="{ name: 'teams'}">TunnelBear for Teams</router-link></li>
                  <li><a :href="external.help">Help</a></li>
                  <li><router-link :to="{ name: 'overview'}">My Account</router-link></li>
                </ul>
              </div>
              <div class="footer-column">
                <h6>Apps</h6>
                <ul>
                  <li><router-link :to="{ name: 'ios'}">iPhone & iPad</router-link></li>
                  <li><router-link :to="{ name: 'android'}">Android</router-link></li>
                  <li><router-link :to="{ name: 'mac'}">Mac</router-link></li>
                  <li><router-link :to="{ name: 'windows'}">Windows</router-link></li>
                  <li><router-link :to="{ name: 'browser'}">Browser</router-link></li>
                  <li><router-link :to="{ name: 'blocker'}">Blocker</router-link></li>
                </ul>
              </div>
              <div class="footer-column">
                <h6>Company</h6>
                <ul>
                  <li><a :href="ENV.blogURL">Blog</a></li>
                  <li><router-link :to="{ name: 'about'}">About Us</router-link></li>
                  <li><router-link :to="{ name: 'jobs-index'}">Jobs</router-link></li>
                  <li><router-link :to="{ name: 'affiliate'}">Affiliate</router-link></li>
                  <li><router-link :to="{ name: 'internet-freedom-hub'}">Internet Freedom Hub</router-link></li>
                  <li><router-link :to="{ name: 'privacy'}">Privacy Center</router-link></li>
                  <li><router-link :to="{ name: 'privacy-policy'}">Privacy Policy</router-link></li>
                  <li><router-link :to="{ name: 'terms-of-service'}">Terms of Service</router-link></li>
                </ul>
              </div>
              <div class="footer-column">
                <h6>Follow Us</h6>
                <ul class="horizontal">
                  <li>
                    <a :href="external.facebookUrl" class="icon-parent">
                      <i class="icon icon-facebook">
                        <span>Facebook</span>
                      </i>
                    </a>
                  </li>
                  <li>
                    <a :href="external.instagramUrl" class="icon-parent">
                      <i class="icon icon-instagram">
                        <span>Instagram</span>
                      </i>
                    </a>
                  </li>
                  <li>
                    <a :href="external.twitterUrl" class="icon-parent">
                      <i class="icon icon-twitter">
                        <span>Twitter</span>
                      </i>
                    </a>
                  </li>
                  <li>
                    <a :href="external.telegramUrl" class="icon-parent">
                      <i class="icon icon-telegram">
                        <span>Telegram</span>
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="essentials" class="text-center" v-if="!isTbMcAfeeLandingPage">
              <h6>Get the Bear Essentials</h6>
              <div class="apps">
                <router-link :to="{name: 'download'}" class="dark">
                  <div class="app">
                    <img src="./images/tunnelbear-app.svg" alt="TunnelBear">
                    <span>TunnelBear</span>
                  </div>
                </router-link>
              </div>
            </div>
            <div v-if="isTbMcAfeeLandingPage" class="text-center">
              <img v-if="isTbMcAfeeLandingPage" class="mbottom-20" src="~@/images/tb-mcafee-logo-grey.svg" alt="TunnelBear">
              <p>&copy;{{year}} TunnelBear&nbsp;LLC.</p>
              <p>Grizzly regards from Toronto,&nbsp;Canada</p>
            </div>
            <div v-else id="signature" class="text-center">
              <img src="~@/images/logo-grey.svg" alt="TunnelBear">
              <p>&copy;{{year}} TunnelBear&nbsp;LLC.</p>
              <p>Grizzly regards from Toronto,&nbsp;Canada</p>
            </div>
          </div>
        </div>
      </section>
      <div class="view-wrap minimal" v-if="minimal">
        <div class="section-inner">
          <div class="container text-center">
            <p class="fine-print-lg">&copy;{{year}} TunnelBear LLC.</p>
          </div>
        </div>
      </div>
      <div v-if="pi" class="pi-video"></div>
      <div class="pi" @click="piClick($event)">π</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'site-footer',
  props: ['minimal'],
  data () {
    return {
      year: new Date().getFullYear(),
      pi: false
    }
  },
  computed: {
    isTbMcAfeeLandingPage: function () {
      return this.$route.name === 'tb-by-mcafee' || this.$route.name === 'tb-by-mcafee-checkout'
    },
    modalOpen: function () {
      return this.$store.getters.modalOpen
    }
  },
  methods: {
    piClick: function (e) {
      if (e.shiftKey) {
        this.pi = true
        setTimeout(() => {
          this.pi = false
        }, 7000)
      }
    }
  }
}
</script>

<style scoped lang="stylus" src="./footer.styl" >

<template>
  <nav id="main-header">
    <div class="nav-inner complex">
      <div class="top-bar">
        <router-link :to="{ name: 'tb-by-mcafee'}" class="logo">
          <img class="hidden-xs" src="../../images/tb-by-mcafee.svg" alt="TunnelBear by McAfee">
          <img class="visible-xs" src="~@/images/t-grey.svg" alt="TunnelBear by McAfee">
        </router-link>
      </div>
      <div class="drawer">
        <div id="nav-items">
          <div class="nav-menu">
           <div class="nav-item bottom">
              <router-link :to="{ name: 'tb-by-mcafee-checkout', query: routeQueryYearly }" class="btn">Get TunnelBear</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'nav-tb-by-mcafee',
  data () {
    return {
      utmQuery: ''
    }
  },
  created: function () {
    this.utmQuery = this.$route.query.utm_source
  },
  computed: {
    routeQueryYearly: function () {
      let query = ''
      if (this.utmQuery === '1641') {
        query = { plan: 'tb_mcafee_yearly_1641', utm_source: 'utm_1641' }
      } else {
        query = { plan: 'tb_mcafee_yearly_739', utm_source: 'utm_739' }
      }
      return query
    }
  }
}
</script>

import Cookies from 'js-cookie'

export default {
  windowScrollTop () {
    return (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
  },
  preloadImages (imgSrcs) {
    return new Promise((resolve) => {
      const images = []
      const promises = []

      for (let i = 0; i < imgSrcs.length; i++) {
        promises[i] = new Promise(resolve => {
          images[i] = new Image()
          images[i].addEventListener('load', function handler () {
            this.removeEventListener('load', handler)
            resolve()
          })
          images[i].src = imgSrcs[i]
        })
      }

      Promise.all(promises).then(() => {
        resolve(images)
      })
    })
  },
  pushMktDataLayer () {
    const tbMkt = Cookies.getJSON('tb_mkt')
    if (tbMkt) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'tb_mkt': tbMkt })
    }
  },
  urlEncodeData (data) {
    let buffer = []
    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) continue
      const encodedKey = encodeURIComponent(key)
      const encodedValue = encodeURIComponent(data[key])
      buffer.push(encodedKey + '=' + encodedValue)
    }
    buffer = buffer.join('&')
    return buffer
  },
  cleanQuery (query) {
    if (!query) return {}
    return query.split('&').reduce(function (obj, curr, i, arr) {
      const item = curr.split('=')
      obj[decodeURIComponent(item[0])] = decodeURIComponent(item[1])
      return obj
    }, {})
  },
  formatDate (date) {
    const dateObj = new Date(date)

    if (!(dateObj instanceof Date && isFinite(dateObj))) return ''

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const dateStr = months[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear()
    return dateStr
  },
  numericalDate: function (dateInput) {
    const date = new Date(dateInput)
    let day = date.getDate()
    let month = date.getMonth() + 1
    const year = date.getFullYear()
    if (day < 10) day = '0' + day
    if (month < 10) month = '0' + month
    return {
      day,
      month,
      year
    }
  },
  savePercent: function (yearlyPrice, monthlyPrice, bearType) {
    const large = parseFloat(yearlyPrice)
    const small = parseFloat(monthlyPrice)
    let result = Math.abs(small * 12 - large) / (small * 12)
    if (bearType === 'kodiak') {
      result = Math.abs(small * 24 - large) / (small * 24)
    }
    return Math.round(result * 100)
  },
  macOsVersion: function () {
    const os = 'Mac OS X '
    const ua = navigator.userAgent
    const firefox = ua.toLowerCase().indexOf('firefox') > -1
    const start = ua.indexOf(os) + os.length
    const end = firefox ? ua.substring(start).indexOf(';') : ua.substring(start).indexOf(')')

    let version = ua.substring(start, start + end)

    version = version.match(/(\d+)[_.](\d+)/)[0]
    const versionClean = version.replace('_', '.')
    return parseFloat(versionClean)
  },
  datePlusSevenDays: function () {
    return new Date((new Date()).getTime() + 7 * 24 * 60 * 60 * 1000)
  },
  datePlusMonth: function () {
    const dateObj = new Date()
    return dateObj.setMonth(dateObj.getMonth() + 1)
  },
  debounce: function (callback, wait) {
    let timeoutId = null
    return (...args) => {
      window.clearTimeout(timeoutId)
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args)
      }, wait)
    }
  }
}

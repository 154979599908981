<template>
  <div class="nav-dropdown">
    <!-- Desktop markup -->
    <div class="dropdown-desktop">
      <div class="dropdown-wrap">
        <div class="nav-item chevron-icon chevron-focus-rotate" @click="handleLinkClick">
          <slot name="link"></slot>
          <div class="dropdown-content-wrap">
            <div class="dropdown-container">
              <div class="dropdown-list" @click="handleContentClick">
                <slot name="content"></slot>
              </div>
              <div class="caret"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile markup -->
    <div class="dropdown-mobile">
      <div class="dropdown-wrap">
        <div class="link-wrap">
          <div class="nav-item" @click="handleLinkClick">
            <slot name="link"></slot>
          </div>
          <button
            class="btn-expand chevron-icon"
            :class="{'rotate': showDropdown}"
            aria-label="expand navigation"
            @click="isDropdown = !isDropdown">
          </button>
        </div>
        <div class="dropdown-content-wrap" :class="{'expand': showDropdown}">
          <div class="dropdown-container">
            <div class="dropdown-list" @click="handleContentClick" :inert="!showDropdown">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import is from 'is_js'

export default {
  name: 'nav-link-apps',
  components: {},
  data () {
    return {
      isDropdown: false
    }
  },
  computed: {
    showDropdown: function () {
      return this.isDropdown
    }
  },
  methods: {
    handleLinkClick: function () {
      this.$emit('dropdownClicked')
    },
    handleContentClick: function (e) {
      const targetIsLink = e.target.tagName.toLowerCase() === 'a'
      if (targetIsLink) {
        this.isDropdown = false
        e.target.blur()
      }
      this.$emit('dropdownClicked')
    }
  }
}
</script>

<style lang="stylus" src="./nav-links-dropdown.styl">
<style lang="stylus" src="../../nav.styl">
